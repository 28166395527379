'use strict';

$(document).ready(function() {
	//When page loads...
	$(".tab_content").hide(); //Hide all content
	$("ul.tabs").each(function(idx, elem) {
		var $ul = $(elem);

		$ul.find("li:first").addClass("active").show();
		$ul.siblings("div.tab_container").find(".tab_content:first").show();
	});

	// 2016/11/22.PAC - allow li to handle click rather than span, so any ::before or ::after can react in same way
	//$(document).on("click", "ul.tabs li span", function() {
	//	var li = $(this).parent("li");
	$(document).on("click", "ul.tabs li", function() {
		//var li = $(this).parent("li");
		var li = $(this);
		select_tab(li);
		return false;
	});

	$(document).on("mouseenter", "ul.tabs li.closeable span", function() {
		$(this).append("<button class='tab_close'>[x]</button>");
	});

	$(document).on("mouseleave", "ul.tabs li.closeable span", function() {
		$(this).find('button').remove();
	});

	$(document).on("click", "ul.tabs li.closeable span button", function() {
		// close tab by passing the span
		close_tab($(this).parent());
	});

	$(document).on("change", "ul.tabs li", function() {
		var $li = $(this),
		$container = $li.parents("ul:first").siblings("div.tab_container");

		if ($li.hasClass("active")) {
			return false;
		}

		// clear active tab, then select target tab
		$li.siblings("li").removeClass("active");
		$li.addClass("active");
		clear_unread_count($li);

		// hide all tab containers
		$container.find(".tab_content").each(function(idx, elem) {
			$(elem).hide();
		});

		// Find the href attribute value to identify the active tab + content
		var activeTabName = $li.find("span").data("tabname"),
			$activeTab = $("#"+activeTabName);

		if ($container.data("transition") === "instant") {
			$activeTab.show();
		} else {
			$activeTab.fadeIn(); //Fade in the active ID content
		}

		if ($li.data("url") ) {
			var now = new Date(),
				lastUpdated = $li.data("lastupdated"),
				shouldUpdate = true;

			if (lastUpdated) {
				shouldUpdate = false;

				if ($li.data("cachefor")) {
					if (now.getTime() - parseInt(lastUpdated, 10) > (parseInt($li.data("cachefor"), 10) * 1000)) {
						shouldUpdate = true;
					}
				}
			}

			if (shouldUpdate) {
				load_tab_content($li, $activeTab);
			}
		}

		return false;
	});

	$("ul.tabs li").bind("reload", function() {
		load_tab_content($(this), $($(this).find("a").attr("href")));
	});
});

var clear_unread_count = function($li) {
	$li.removeAttr('data-unread');
	$li.removeClass('new-message');
};

var load_tab_content = function($li, $tab) {
	var now = new Date();

	$li.data("lastupdated", now.getTime());
	$tab.html("Loading...");

	$tab.load($li.data("url"));
};

var select_tab = function(li) {
	if (!li.hasClass("active")) {
		li.trigger("change");
	}
};

var close_tab = function(tab) {
	var li, span, parentid;

	// allow passing of .tab_content div, and resolve actual tab from that
	if (tab.is("div") && (tab.hasClass("tab_content")))
	{
		parentid = tab.data("parent");
		if (parentid !== '') {
			tab = $("#"+parentid);
		}
	}

	if (!tab.is("span") && !tab.is("li")) {
		return;
	}

	// allow tab to refer to either the li or the inner span
	if (tab.is("span"))
	{
		span = tab;
		li = span.parent("li");
	}
	else
	{
		li = tab;
		span = li.find("span");
	}

	var	isactive = li.hasClass("active"),
		next_tab = li.prev("li");

	if ((next_tab.length <= 0) || (next_tab.prop('id') === 'tab_room')) {
		next_tab = li.next("li");
	}

	if (next_tab.length <= 0) {
		next_tab = $('#tab_room');
	}

	// trigger a close event to the tab, passing the next visible tab
	li.trigger("close", { next_tab: next_tab });

	// remove
	$('#'+$(span).data('tabname')).remove();
	li.remove();

	// select next available tab
	if ((isactive) && (next_tab.length > 0)) {
		select_tab(next_tab);
	}
};
