;(function($) {
	var methods = {
		init: function(options) {
			return this.each(function() {
				var $this = $(this),
					button = $this.find('.button');

				$this.on('click', function() {
					button.toggleClass('pressed');
					$this.trigger('chat:togglebutton:' + (button.hasClass('pressed') ? 'down' : 'up'));
				});

				return $this;
			});
		},

		press: function() {
			$(this).find('.button').addClass('pressed');
		},

		release: function() {
			$(this).find('.button').removeClass('pressed');
		}
	};

	$.fn.toggleButton = function(method) {
		if (typeof method === 'object' || !method) {
			return methods.init.apply(this, arguments);
		} else if (methods[method]) {
				return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
		} else {
			$.error('Method ' + method + ' doesn\'t exist on toggleButton');
		}
	};
})(jQuery);

