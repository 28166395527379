'use strict';
/* global Sha256: false */
(function(root, factory) {
	if (typeof exports === 'object') {
		root.Sha256 = require('./sha256');
		module.exports = factory();
	} else {
		root.Net = factory();
	}
}((typeof window !== 'undefined') ? window : global, function() {

	function Net(client) {
		this.client = client;
		this.emithook = null;

		this.set_emithook = function(origin, fn) {
			this.emit_origin = origin;
			this.emithook = fn;
		};

		this.send = function(message, data) {
			// TODO_CARPII: try to remove this for live, although its safe
			if (typeof exports === 'object') {
				if (this.emithook !== null)
				{
					this.emithook(this.emit_origin, message, data);
				}
			}
			this.client.socket.emit(message, data);
		};

		this.send_message = function(data) {
			this.send('msg', data);
		};

		this.send_private_message = function(data) {
			if (!data.msg || data.msg === '') {
				return;
			}

			var payload = {
				type: 'private',
				message: data.msg,
				colorid: this.client.colorid,
				avatarid: this.client.state.get_options().avatarid,
				username: data.username
			};

			this.send('msg', payload);

		};

		this.save_options = function() {
			var options = this.client.state.get_options();
			if (options !== null) {
				this.send("setopt", { data: options });
			}
		};

		this.save_usersounds = function() {
			var sounds = this.client.state.get_usersounds();
			if (sounds !== null) {
				this.send("setsounds", { data: sounds });
			}
		};

		this.save_privacy_options = function() {
			var options = this.client.state.get_privacy_options();
			if (options !== null) {
				this.send("setpriv", { data: options });
			}
		};

		/*this.block_user = function(username, userid) {
			this.send('block_user', { blockusername: username, blockid: userid });
		};*/

		this.block_user_by_username = function(username) {
			this.send('block_user', { blockusername: username });
		};

		this.join_room = function(roomid) {
			/*console.log("net.join_room() -> room id = " + roomid);
			if (roomid === this.client.state.get_room_id()) {
				console.log("net.join_room() -> room id = " + roomid + " BUT ALREADY IN ROOM ACCORDING TO STATE");
			}*/
			this.send("join", { roomid: roomid });
		};

		this.unblock_user = function(username, userid) {
			this.send('delete_user_block', { blockusername: username, blockid: userid });
		};

		this.request_room_list = function() {
			this.send("rooms", { });
		};

		this.add_friend = function(userid) {
			this.send('add_friend', { userid: userid });
		};

		this.accept_friendship = function(userid) {
			this.send('accept_friendship', { userid: userid });
		};

		this.decline_friendship = function(userid) {
			this.send('decline_friendship', { userid: userid });
		};

		this.room_list = function() {
			this.send('rooms', { });
		};

		this.room_counts = function() {
			this.send('room_counts', { });
		};

		this.room_peek = function(roomid) {
			this.send('roompeek', { roomid: roomid });
		};

		this.remove_friend = function(userid) {
			this.send('remove_friend', { userid: userid });
		};

		this.login = function(username, token, challenge, version, buildVersion) {
			var hash = Sha256.hash(token + challenge);
			this.send('login', {
				username: username,
				passhash: hash,
				version: version,
				build: buildVersion
			});
		};

		this.get_block_list = function() {
			this.send('get_block_list', { });
		};

		this.get_user_meta_packet = function(username) {
			this.send('get_user_meta', {username: username});
		};
	}
	return Net;
}));
