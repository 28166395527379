'use strict';
/*jshint unused: vars */
/* global io: false, _: false, Net: false, State: false */
/* global select_tab, close_tab, Clipboard */
/* global chat_username, chat_request_token, chat_images_host, chat_thumbs_host, chat_web_host, emojione */

var scancodes = {
	ESC: 27,
	ENTER: 13,
	COMMAND: 91,
	F2: 113
};

var soundMapDelta = {};

var client = {
	version: 101,
	build: readBuildVersion(),
	origin_hostname: getOriginHostname(),
	default_privacy_status: 'available',
	autojoin_room_id: 0,
	socket: null,
	options_temp: null,
	colorid: 0,
	color_rgb: "#000",
	fontbold :false,
	fontitalic :false,
	fontid: 0,
	last_chatinput: [],
	websockets_only: true,
	defer_userlist_sort: false,
	deferTimeout: null,
	mobile_mode: false,
	zindex: 0,
	selection: "",
	hosts: {
		images: null,
		thumbs: null,
		web: 'tvchix.com'
	},
	peek_roomid: 0,
	peek_roomname: "",

	// updated by on_connect()
	// TODO_CARPII: Remove default values here?
	// TODO_CARPII: Collect these into a connection struct?
	disconnect_reason: null,
	disconnect_reason_iscomplete: false,
	fatalling: false,
	max_reconnect_tries: 4,
	reconnect_tries: 0,
	reconnect_timeout: null,
	successful_connect: false,
	public_message_target: null,
	join_in_progress: false,
	previous_room_item: null,
	last_mail_id: 0,
	unread_count: 0,
	last_emoji_editor: [],
	emoji_map: {
		':)': ':slight_smile:',
		':D': ':laughing:'
	},
	firefox_workaround: false
};

function isMobileDevice() {
	return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}

var Widget = {
	cache: {},

	DropDownListFromTemplate: function(selector, template, templateParams, jqxArguments) {
		var $el = $(selector),
			elem = $el[0];

		if (!Widget.cache[elem.id]) {
			Widget.cache[elem.id] = elem.outerHTML;
		} else {
			$el.replaceWith(Widget.cache[elem.id]);
		}

		$(selector).html(client.compile_template(template, templateParams)).jqxDropDownList(jqxArguments);
	}
};

function getOriginHostname() {
	if (window.location && window.location.hostname) {
		var hostname = window.location.hostname,
			parts = hostname.toLowerCase().split('.');

		if (parts.length < 3) {
			return hostname;
		}

		return parts.slice(-2, parts.length).join('.');
	}
}

function readBuildVersion() {
	if (window.location && window.location.pathname) {
		var path = window.location.pathname,
			match = path.match(/^\/(\d{3,})\//);

		if (match && match[1]) {
			return match[1];
		}
	}

	return 100;
}

client.templates = new Templates();

client.on_connect = function() {
	this.state.message_id = 0;
	this.state.room.id = 0;
	this.disconnect_reason = null;
	this.disconnect_reason_iscomplete = false;
	this.fatalling = false;
	this.reconnect_tries = 0;
	this.join_in_progress = false;
	if ((this.reconnect_timeout) && (this.reconnect_timeout > 0)) {
		clearTimeout(this.reconnect_timeout);
		this.reconnect_timeout = null;
	}

	this.gui_disable_keyboard();
	this.gui_hide_user_popup();
	this.gui_hide_maillist();
};

client.on_disconnect = function() {
	this.reconnect_tries = 0;
	this.gui_disable_keyboard();
	this.gui_hide_user_popup();
};

client.get_reconnection_delays = function() {
	var mind = 3, maxd = 7, mindm = 8, maxdm = 15,
		delays = {
			reconnectionDelay: Math.random() * (maxd - mind) + mind,
			reconnectionDelayMax: Math.random() * (maxdm - mindm) + mindm
		};

	return delays;
};

client.is_tvchix_domain = function(url) {
	var matches = url.match(/^https?:\/\/([^\/$]+)/);

	if (matches.length === 2) {
		var hostname = matches[1];

		if (hostname === client.origin_hostname) {
			return true;
		}

		var parts = hostname.split('.');
		if (parts.length > 2) {
			var domain = parts.splice(-2).join('.');

			if (domain === client.origin_hostname) {
				return true;
			}
		}
	}

	return false;
};

client.process_url = function(url) {
	if (client.is_tvchix_domain(url)) {
		url = url.replace(/^https?:/, '');
	}

	return url;
};

client.hyperlink_any_urls = function(str) {
	// see also url_utils.js apply_bbcode_to_urls
	var newstr = str,
		re = /(?:http|https):\/\/\S+\.\S+/g,
		handled = [],
		match;

	while ((match = re.exec(str)) !== null) {
		var url = match[0],
			label = url,
			matchLower = url.toLowerCase();

		if (handled.indexOf(matchLower) < 0) {
			handled.push(matchLower);

			// ensure any regex chars are replaced so that the case-insensitive match doesn't fail
			var escapedMatch = url.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
			newstr = newstr.replace(new RegExp(escapedMatch, 'ig'), client.create_anchor(client.process_url(url), label));
		}
	}

	return newstr;
};

client.parse_username_from_url = function() {
	// temporary function allowing username to be specified in GET params, for development
	// login credentials would eventually just be output from PHP when generating chat index.php
	var prmstr = window.location.search.substr(1);
	var prmarr = prmstr.split ("&");
	var params = {};
	var result = {};

	for (var i = 0; i < prmarr.length; i++)
	{
		var tmparr = prmarr[i].split("=");
		params[tmparr[0]] = tmparr[1];
	}

	if (params.username)
	{
		result.username = params.username;

		//this.state.set_login(params.username, (params.token) ? params.token : null);
		//this.state.set_login(params.username, this.state.token);
		// TODO_CARPII: document.title = "[" + this.state.username + "]";
	}

	if (params.roomid)
	{
		result.autojoin_room_id = parseInt(params.roomid, 10);
	}

	return result;
};

client.gui_get_privid_of_chatinput = function($input) {
	var id = "";

	if ($input.length > 0) {
		var container = $input.closest("div.privinnercontainer");
		if (container.length > 0) {
			id = container.attr("id");
		}
	}

	return id;
};

client.gui_hide_all_emojione_pickers = function($excluding_id) {
	// hide all visible emojione pickers
	$("div.emojionearea-picker:visible").each(function() {
		var parentInput = $(this).closest("div.emojionearea").siblings("input.chatinput");
		var newid = client.gui_get_privid_of_chatinput(parentInput);
		if (newid !== $excluding_id) {
			var realarea = client.get_emojipicker(parentInput);
			realarea.hidePicker();
		}
	});
};

client.gui_set_last_chatinput = function($input, $focusing) {
	var oldid = this.gui_get_privid_of_chatinput(this.last_chatinput);
	var newid = this.gui_get_privid_of_chatinput($input);

	if ($focusing) {
		this.gui_hide_all_emojione_pickers(newid);
	}
	else {
		this.gui_hide_all_emojione_pickers(oldid);
	}

	this.last_chatinput = $input;
};

client.gui_focus_last_chatinput = function() {
	if (client.last_chatinput.length === 0) {
		return;
	}

	var emojipicker = client.last_chatinput.next('.emojionearea');
	if (emojipicker.is(':visible')) {
		client.gui_focus_input(client.last_chatinput);
	}
};

client.gui_set_window_title = function(username) {
	document.title = "[" + username + "]";
};

client.gui_respond_to_ESC = function() {
	var hasfocus = $('div.dialog.hasfocus');

	if (hasfocus.length === 0) {
		var tabbed_chat = client.gui_get_active_chatarea();

		if (tabbed_chat.length > 0) {
			hasfocus = tabbed_chat.parents('.privinnercontainer:first');
		}
	}

	if (hasfocus.length <= 0) {
		var dialogs = $(".dialog:visible");
		if (dialogs.length <= 0) {
			return;
		}

		var cancelbuttons = dialogs.find("button.btncancel:first");
		if (cancelbuttons.length > 0) {
			cancelbuttons.click();
		}

		return;
	}

	if (client.state.options.key_esc_closes) {
		hasfocus.find('.pclose, .close').click();
	}
	else {
		hasfocus.find('.pminimise').click();
	}
	client.gui_focus_clearall();
	client.gui_focus_next_available_privdialog();
};

client.gui_show_session_invalid = function() {
	this.gui_show_dialog("Your chatroom session has expired.\n\nPlease log back into tvChix Chat from the website");
};

client.build_popup = function() {
	$("#userpopupbody").html("");
	this.add_popup_item('profile', null, true, 'View Profile', '');
	this.add_popup_item('priv', null, false, 'Private Message', 'noviewonly nooffline is_available' + (client.chatmod ? ' is_chatmod' : ''));
	this.add_popup_item('nopriv', null, false, 'Unavailable for Private Message', 'noviewonly nooffline is_unavailable' + (client.chatmod ? ' is_chatmod' : ''));
	this.add_popup_item('personal_room_message', null, false, 'Room Message', 'noviewonly nooffline nomatchtarget nooutofchan');
	this.add_popup_item('clear_room_message', null, false, 'Clear Room Message', 'noviewonly nooffline matchtarget');
	this.add_popup_item('block', null, false, 'Block', 'noviewonly');
	this.add_popup_item('unblock', null, false, 'Unblock', 'noviewonly');
	this.add_popup_item('add_friend', null, false, 'Add friend', 'noviewonly nooffline');
	this.add_popup_item('remove_friend', null, false, 'Remove friend', 'noviewonly');
	this.add_popup_item('confirm_friend', null, false, 'Confirm friendship', 'noviewonly');
	this.add_popup_item('request_sent', null, false, 'Request sent!', 'noviewonly');
};

client.compile_template = function(template, params) {
	var compiled = _.template(template);
	return compiled(params);
};

client.add_popup_item = function(id, href, newtab, label, cssclass) {
	$("#userpopupbody #menulink_"+id).remove();

	var css = "menuitem" + ((typeof cssclass !== "undefined") ? " " + cssclass : "");
	var h = this.compile_template(this.templates.popup, { vars: {id: id, href: href, newtab: newtab, css: css, label: label } });
	$("#userpopupbody").append(h);
};

client.gui_get_target_room_chatarea = function() {
	// TODO_CARPII: first stages at genericising room/tab chatarea
	// TODO_CARPII: eventually needs to accept roomid so we can target non-active room tabs
	return $("#chatarea");
};

client.check_shortcuts = function(msg, username) {
	//msg = msg.replace(/\/me/g, username ? username : this.state.username);
	return msg;
};

client.test_message_self_action = function(msg, username) {
	if (/^\/me /i.test(msg))
	{
		msg = msg.replace(/^\/me /, "");
		return msg;
	}
	return false;
};

client.gui_set_selected_room = function(roomid) {
	//$("#jqxroomselect").jqxDropDownList({ selectedIndex: roomid });
	//console.log("setting room id to: " + roomid);

	$("#jqxroomselect").data("ignoreselect", 1);
	$("#jqxroomselect").val(roomid);
	$("#jqxroomselect").data("ignoreselect", 0);

	//console.log("roomselect val = ");
	//console.log($("#jqxroomselect").val());
};

client.gui_set_room_title = function(state) {
	var roomname = state.get_room_name(),
		room_user_count = state.get_room_user_count(),
		title = roomname + ' (' + room_user_count + ')';

	$("ul#roomtabs li#tab_room span").text(title);
};

client.gui_joined_room = function(state) {
	var roomid = state.get_room_id();
	this.join_in_progress = false;
	//console.log("client.gui_joined_room, joining client.state.room which is " + roomid);

	this.gui_clear_public_message_target();
	this.gui_set_selected_room(roomid);
	this.gui_set_room_title(state);
};

client.send_input_and_clear = function(chatinput) {
	var options = this.state.get_options(),
		picker = client.get_emojipicker(chatinput),
		input = client.sanitize_input(picker.getText()),
		data = { type: 'public', message: input };

	picker.setText('');
	picker.hidePicker();

	$(chatinput).next(".inputreplacement").empty();

	// Check if message is empty
	if (data.message === '') {
		return;
	}

	if (!(this.state.room && this.state.room.id)) {
		return;
	}

	data.roomid = this.state.room.id;

	if (this.fontid > 0) {
		data.fontid = this.fontid;
	}

	if (this.colorid > 0) {
		data.colorid = this.colorid;
	}

	if (options.avatarid > 0) {
		data.avatarid = options.avatarid;
	}

	if (this.fontbold) {
		data.fontbold = this.fontbold;
	}

	if (this.fontitalic) {
		data.fontitalic = this.fontitalic;
	}

	// Check if message is targeted
	if (this.public_message_target) {
		data.target = this.public_message_target;
	}

	// Send the message through the socket to the server
	this.net.send_message(data);
};

client.sanitize_input = function(input) {
	if (input.length > 10) {
		if (input.toUpperCase() === input) {
			input = input.toLowerCase();
		}
	}

	return input;
};

client.gui_get_input_replacements = function() {
	return $("input.chatinput:not(.priv) + .inputreplacement");
};

client.gui_set_message_color = function(colorid) {
	// TODO_CARPII: Make this relative and not #id-based, so it affects the associated room tab chatinput
	var swatch_rgb,
		swatch = $("#colorpicker div.color[data-colorid='"+colorid+"']");

	if (swatch.length <= 0) {
		return;
	}

	swatch_rgb = swatch.css("background-color");
	this.colorid = colorid;
	this.color_rgb = swatch_rgb;

	$("input.chatinput:not(.priv)").css("color", swatch_rgb);
	$("#colorpickerbtn span.colorpickerpreview").css("color", swatch_rgb);
	this.gui_get_input_replacements().css("color", swatch_rgb);
};

client.send_priv_input_and_clear = function(privchatinput) {
	var picker = client.get_emojipicker(privchatinput);

	if (!picker) {
		return;
	}

	var container = privchatinput.closest('.privinnercontainer');
	if (container.length > 0) {
		container.data('closecount', 0);
	}

	var msg = client.sanitize_input(picker.getText().trim()),
		to_username = container.data("username"),
		data = {message: msg, username: this.state.username, type: 'priv' },
		$privarea = container.find(".chatarea.priv");

	if (msg === '') {
		return;
	}

	if (container.hasClass("privinert")) {
		return;
	}

	picker.setText('');
	picker.hidePicker();
	privchatinput.next(".inputreplacement").empty();

	var raw_message = data.message;
	data.message = client.hyperlink_any_urls(data.message);
	data.colorid = this.colorid;
	this.gui_addmsg_priv($privarea, data);
	this.net.send_private_message({msg: raw_message, username: to_username});
};

client.gui_prune_chatarea = function($chatarea, limit) {
	if ($chatarea.find("div.message").length > limit) {
		$chatarea.find("div.message:first").remove();
	}
};

client.warn_user_about_window_close = function(container, force) {
	var username = container.data('username'),
		user = client.state.get_user_by_username(username),
		close_attempts = container.data('closecount') || 0,
		profilegroup;

	close_attempts++;

	if (user) {
		profilegroup = user.profilegroup;
	} else {
		profilegroup = container.data('profilegroup');
	}

	if (!force) {
		if (!client.state.allow_private_message(profilegroup, client.state.privacy_options)) {
			if (!client.state.is_friend(username)) {
				container.data('closecount', close_attempts);
				if (close_attempts < 2) {
					var $parent = container.find(".chatarea.priv").first(),
						data = {message: 'Warning: closing this window means ' + username + ' will be unable to send you further messages due to your messaging filters', type: 'system' };

					this.gui_addmsg_priv($parent, data);

					return true;
				}
			}
		}
	}

	return false;
};

client.gui_close_tab_which_contains = function(item, force) {
	// find tab relating to priv window
	var tabcontent = item.closest(".tab_content");
	var tab_id = tabcontent.data("parent");
	var tab_li = $("#"+tab_id);

	if (client.warn_user_about_window_close(item.closest('.privinnercontainer'), force)) {
		return;
	}

	// call close on the tab (which in turn fires tab_close event for cleanup)
	close_tab(tab_li);
};

client.gui_get_active_chatarea = function() {
	var active_tab_id = client.gui_get_active_tab().find('span').data('tabname'),
		$chatarea = $('#maintabcontainer').find('#' + active_tab_id).find('.chatarea');

	return $chatarea;
};

client.gui_get_active_chatinput = function() {
	var $chatarea = client.gui_get_active_chatarea(),
		input = $chatarea.parent(".chatarea-container").parent().find("input.chatinput");

	return input;
};

client.gui_get_active_tab = function() {
	var $active_tab = $('#roomtabs li.active');

	if (!$active_tab) {
		$active_tab = $('#roomtabs li:first-child');
	}

	return $active_tab;
};

client.gui_select_next_tab = function() {
	var $active_tab = client.gui_get_active_tab(),
		$next_tab = $active_tab.next('li') || $active_tab;

	client.gui_select_tab($next_tab);
	client.gui_scroll_active_tab_to_view();
};

client.gui_select_prev_tab = function() {
	var $active_tab = client.gui_get_active_tab(),
		$prev_tab = $active_tab.prev('li') || $active_tab;

	client.gui_select_tab($prev_tab);
	client.gui_scroll_active_tab_to_view();
};

client.gui_scroll_active_tab_to_view = function() {
	var $active_tab = $('#roomtabs li.active'),
		$first_tab = $('#roomtabs li:first-child'),
		$bar = $('#roomtabs'),
		margin = parseInt($first_tab.css('margin-left'), 10),
		tab_box = $active_tab[0].getBoundingClientRect(),
		bar_box = $bar[0].getBoundingClientRect(),
		tab_buttons_width = $("#tab-left").outerWidth() + $("#tab-right").outerWidth(),
		$background = $("#background"),
		margin_offset = ($background.hasClass("control-left") && !$background.hasClass("control-hide")) ? (tab_buttons_width - 1) : 0,
		new_margin = 0;

	// TODO_NORC: These shouldn't need ths +12 but I can't work out where the extra is coming from
	if (tab_box.right > bar_box.width) {
		new_margin = margin - Math.floor(tab_box.right - bar_box.width) + 12 + margin_offset;
	}
	else if (tab_box.left < 0) {
		new_margin = margin - Math.floor(tab_box.left) + 12 + margin_offset;
	}

	if ($active_tab.prop("id") === $first_tab.prop("id")) {
		new_margin = 0;
	}

	if (new_margin > 0) {
		new_margin = 0;
	}

	$first_tab.css('margin-left', new_margin);
};

client.gui_close_privwindow = function($privwin, force) {
	if (client.warn_user_about_window_close($privwin.find('.privinnercontainer'), force)) {
		return;
	}

	$privwin.remove();
	this.gui_focus_next_available_privdialog();
};

client.gui_close_minimised_priv = function(username) {
	var btn = this.gui_get_minimised_button_for_username(username);

	// TODO_CARPII: Review parent selector
	// BUG: Does not remove dialog container properly
	var container = $('#privmsg_' + $(this).data('username')).parent(".privcontainer").parent(".privdialog");
	if (container.length > 0)
	{
		container.remove();
	}

	if (btn.length > 0) {
		btn.remove();
	}
	client.gui_update_minimised_area();
};

client.gui_close_sysdlg = function($sysmsg) {
	$sysmsg.remove();
};

client.gui_close_sysdlg_deferred = function(this_sysmsg) {
	// we use a timer otherwise the link div is removed, and browser doesnt get a chance to handle url open
	setTimeout(function(){ client.gui_close_sysdlg(this_sysmsg); }, 0);
};

client.gui_minimise_priv_window = function(minbutton) {
	var container = minbutton.parents('.privcontainer:first'),
		dlg = container.parents('.dialog.privdialog:first'),
		innercontainer = container.find(".privinnercontainer"),
		username = innercontainer.attr("data-username"),
		this_client = this;

	dlg.hide();

	var label = $('<div/>').text(username);
	var button = $('<button>')
		.addClass('minimised')
		.toggleClass('btninert', innercontainer.hasClass('privinert'))
		.attr('data-username', username)
		.appendTo('#minimisedarea')
		.one('click', function() {
			this_client.gui_unminimise_priv_window($(this));
		});

	button.append(label);

	innercontainer.removeClass("privtabified").addClass("privminimised");

	this.gui_update_minimised_area();

	this.gui_focus_next_available_privdialog();
};

client.gui_scroll_minimised_area_right = function() {
	var $bar = $("#minimisedarea"),
		$first_button = $bar.find("button:first"),
		margin = parseInt($first_button.css('margin-left'), 10),
		bar = $bar[0].getBoundingClientRect();

	$bar.find("button").each(function() {
		var $box = $(this),
			box = $box[0].getBoundingClientRect(),
			box_relative_right = box.right - bar.left;

		if (box_relative_right > bar.width) {
			$first_button.css({'margin-left': margin - (box_relative_right - bar.width) - 2});
			return false;
		}
	});
};

client.gui_scroll_minimised_area_left = function() {
	var $bar = $("#minimisedarea"),
		$first_button = $bar.find("button:first"),
		margin = parseInt($first_button.css('margin-left'), 10),
		buttons = $bar.find("button"),
		bar = $bar[0].getBoundingClientRect();

	for (var i = buttons.length - 1; i >= 0; i--) {
		var $box = $(buttons[i]),
			box = $box[0].getBoundingClientRect();

		if (box.right <= bar.right) {
			var new_margin = margin + bar.right - box.left;
			if (new_margin > 0) {
				new_margin = 0;
			}
			$first_button.css({'margin-left': new_margin});
			break;
		}
	}
};

client.gui_update_minimised_area = function() {
	var $bar = $("#minimisedarea"),
		total_tab_width = 0,
		$buttons = $bar.find("button"),
		$chatarea = this.gui_get_active_chatarea(),
		bar = $bar[0].getBoundingClientRect();

	$buttons.each(function() {
		var w = $(this)[0].getBoundingClientRect().width;
		total_tab_width += w;
	});

	if ($buttons.length > 0) {
		$('#maintabcontainer').addClass('with-minimised-area');

	}
	else {
		$('#maintabcontainer').removeClass('with-minimised-area');
	}

	if ($chatarea.length > 0) {
		this.gui_scroll_chatarea($chatarea);
	}

	if (total_tab_width > bar.width) {
		$('#minimisedarea-container').addClass('show-controls');
	}
	else {
		$('#minimisedarea-container').removeClass('show-controls');
	}

	this.gui_toggle_minimised_area();
};

client.gui_toggle_next_prev_tab_buttons = function() {
	var $bar = $("#roomtabs"),
		total_tab_width = 0,
		bar = $bar[0].getBoundingClientRect();

	$bar.find("li").each(function() {
		var box = $(this)[0].getBoundingClientRect();
		total_tab_width += box.width;
	});

	if (total_tab_width > bar.width) {
		$("#background").removeClass("control-hide");
	}
	else {
		$("#background").addClass("control-hide");
		$("#roomtabs li:first-child").css({"margin-left": 0});
	}
};

client.gui_update_priv_window_meta = function(username, thumbnail, city, county, country, type) {
	var privbanner = $("div.privinnercontainer#privmsg_"+username),
		thumb_html = "",
		userlocation = (city ? city + ", " : "") +
			(county ? county + ", " : "") +
			(country ? country + ", " : "");

	if ((thumbnail) && (thumbnail !== '')) {
		thumb_html = $("<img>").attr("src", thumbnail);
	}

	$(privbanner).find("div.thumbnail").html(thumb_html);
	$(privbanner).find("div.username").text(username);
	$(privbanner).find("div.location").text(userlocation);
	$(privbanner).find("div.profiletype").text(type);
};

client.gui_tabify_priv_window = function(tabbutton, autoselect) {
	var dlg = tabbutton.parents('.privdialog:first'),
		innercontainer = dlg.find('.privinnercontainer:first'),
		username = innercontainer.attr('data-username');
	dlg.hide();

	// create tab
	var tabid = "tab_priv_"+username;

	// avoid dupe tabs
	if ($("#" + tabid).length > 0) {
		return;
	}

	var tab_markup = this.compile_template(this.templates.tab, { vars: {tabid: tabid, username: username, label: username }});

	// create tab container
	var content_id = 'maintab_priv_'+username;
	var content_markup = this.compile_template(this.templates.tab_content, { vars: { divid: content_id, parent_tab_id: tabid }});

	// append tab and tab container
	$("ul#roomtabs").append(tab_markup);

	client.gui_toggle_next_prev_tab_buttons();

	$("div#maintabcontainer").append(content_markup);
	innercontainer.removeClass("privminimised").addClass("privtabified");

	var useritem = this.state.get_user_by_username(username);

	if (typeof useritem !== "undefined") {
		client.gui_update_priv_window_meta(username, useritem.thumb, useritem.city, useritem.county, useritem.country, useritem.usertype_desc);
	} else {
		client.get_user_meta(username);
	}

	// move content from dialog into tab container
	$(innercontainer).detach().appendTo('#'+content_id);

	if (!autoselect) {
		$("#"+content_id).hide();
	}

	$(dlg).remove();

	if (autoselect) {
		// make new tab the active one (false when a new priv msg is rcvd in mobile mode, cos then its just backgrounded as a tab)
		this.gui_select_tab($("li#"+tabid), innercontainer);
		client.gui_scroll_active_tab_to_view();
	}
};

client.gui_untabify_priv_window = function(innercontainer) {
	var username = innercontainer.data("username"),
		parent_tab = innercontainer.closest(".tab_content"),
		container = '';

	innercontainer.removeClass("privtabified");
	innercontainer.detach();
	container = this.gui_create_privcontainer(username, innercontainer);
	this.gui_create_privdialog(username, container);

	if (this.is_keyboard_visible()) {
		this.apply_mobile_keyboard_to_chatarea();
	}

	this.gui_focus_privdialog(container.parent(".privdialog"));

	// remove remaining chrome by closing tab
	close_tab(parent_tab);
};

client.gui_select_tab = function(listitem, privcontainer) {
	select_tab(listitem);

	if (typeof(privcontainer) !== "undefined") {
		this.gui_focus_privdialog(privcontainer);
	}
};

client.gui_relocate_minimised_area = function(listitem) {
	var newparent = $("#maintab_room");
	if (listitem.attr("id") !== "tab_room")
	{
		var tabcontent = listitem.find("span").data("tabname");
		newparent = $("#"+tabcontent).find(".privinnercontainer");
	}

	// move the minimised area so its always contained in active tab
	if (newparent.length > 0)
	{
		$("#minimisedarea-container").detach().appendTo(newparent);
	}
};

client.gui_get_priv_container_for_button = function(maxbutton) {
	var username = maxbutton.data("username");
	var container = $('.privinnercontainer[data-username="' + username + '"]');
	return container;
};

client.gui_toggle_minimised_area = function() {
	var $container = $("#minimisedarea-container");
	if ($("#minimisedarea").find("button.minimised").length > 0) {
		$container.css("display", "block");
	}
	else {
		$container.css("display", "none");
	}
};

client.gui_unminimise_priv_window = function(maxbutton) {
	var innercontainer = this.gui_get_priv_container_for_button(maxbutton);
	var dlg = innercontainer.closest(".dialog.privdialog");

	if (dlg.length > 0)
	{
		dlg.show();
		this.gui_focus_privdialog($(dlg));
	}
	innercontainer.removeClass("privminimised").removeClass("privtabified");
	maxbutton.remove();
	this.gui_update_minimised_area();
	client.gui_focus_last_chatinput();
};

client.gui_on_dialog_dragging_stop = function(e) {
	this.gui_move_dialog_to_within_bounds($(e.target));
};

client.gui_move_dialog_to_within_bounds = function(dlg) {
	if (!dlg.is(':visible')) {
		dlg.css({left: document.body.clientWidth - dlg.width() + 'px', top: $(window).height() - dlg.height() + 'px'});
		return;
	}
	// reposition dialog so that it doesnt extend beyond viewport
	var p = dlg.position(),
		x = p.left,
		y = p.top;

	if (x + dlg.width() > $("#maindiv").innerWidth()) {
		x = $("#maindiv").innerWidth() - dlg.width();
	}

	if (y + dlg.height() > $("#maindiv").innerHeight()) {
		y = $("#maindiv").innerHeight() - dlg.height();
	}

	if (x < 0) {
		x = 0;
	}

	if (y < 0) {
		y = 0;
	}

	if ((x !== p.left) || (y !== p.top)) {
		dlg.offset({ left: x, top: y});
	}
};

client.gui_move_all_dialogs_to_within_bounds = function() {
	var this_client = this;
	$(".dialog.privdialog").each(function() {
		this_client.gui_move_dialog_to_within_bounds($(this));
	});
};

client.gui_position_initial_dialog = function(dlg) {
	// called when a dialog is created, to decide a sensible default position
	var x = Infinity,
		y = 0, btm = 0,
		dlgid = dlg.find(".privinnercontainer").attr("id"),
		container = $("#maintabcontainer"),
		coffset = container.offset(),
		cy = coffset.top,
		ch = container.height(),
		dh = dlg.height();

	// if already some dialogs, cascade relative to bottom rightmost
	if ($(".dialog.privdialog:visible").length > 0)
	{
		$(".dialog.privdialog").each(function() {
			var p = $(this).position();
			var thisid = $(this).find(".privinnercontainer").attr("id");

			// exclude the dialog we are passing
			if (dlgid === thisid) {
				return;
			}

			if (p.top > y) {
				y = p.top;
			}

			if ((p.top + $(this).height()) > btm) {
				btm = p.top + $(this).height();
			}

			if (p.left < x) {
				x = p.left;
			}
		});

		y += 30;
	}

	if ((x <= 0) || (y <= 0)) {
		// no dialogs found, default to top right of chatarea
		x = ($("#maintabcontainer").offset().left + $("#maintabcontainer").width()) - dlg.width() - 10;
		y = $("#maintabcontainer").offset().top + 10;
	}
	else {
		// if some dialogs exist, but we have room to show the new one directly underneath, then do that
		if ((btm + 10) < (cy + ch)) {
			y = btm + 10;
		}
		else {
			// otherwise no room to show it underneath, so underlap it and offset x
			x = x - 30;
		}
	}

	// final check to see if it extends out of viewport, if so reset it back to top right and offset x
	if ((y + dh) > (cy + ch)) {
		x -= 30;
		y = cy + 10;
	}

	dlg.css("top", y+"px");
	dlg.css("left", x+"px");

	// TODO_CARPII: didsable this, as if dlg is newly created then its not visible yet, so would reset position to bottom right
	//this.gui_move_dialog_to_within_bounds(dlg);
};

client.gui_increment_unread_badge = function(target) {
	target.addClass('new-message');
	var unread = target.attr('data-unread');
	target.attr('data-unread', unread ? (parseInt(unread, 10) + 1) : 1);
};

client.gui_addmsg_priv = function($chatarea, data) {
	this.gui_addmsg($chatarea, data);
};

client.gui_check_should_scroll = function($chatarea) {
	if ($chatarea.length <= 0)
	{
		return false;
	}

	//return (($chatarea.scrollTop() + $chatarea.innerHeight()) >= $chatarea[0].scrollHeight);

	// 2016/12/20 - this fucking thing still not working for many people, default back to true
	return true;

	// check if totally scrolled
	var atBottom = ($chatarea[0].scrollHeight - $chatarea.scrollTop() === $chatarea[0].clientHeight);
	/*if (atBottom) {
		console.log("AT BOTTOM");
	}
	else {
		console.log("NOT AT BOTTOM");
	}*/

	return atBottom;

	//return true;

	// 2016/11/01 - This is not working properly. Return true for now, until its fixed (but means people cant scroll up and copy historic chat easily)
	//return ($chatarea[0].scrollHeight > $chatarea.innerHeight());
	//var result = (($chatarea.scrollTop() + $chatarea.innerHeight() + 50) >= $chatarea[0].scrollHeight);
	//return result;

};

client.gui_subtitute_text_for_emoji = function(s) {
	for (var k in client.emoji_map) {
		if (client.emoji_map.hasOwnProperty(k)) {
			s = s.replace(k, client.emoji_map[k]);
		}
	}

	return s;
};

client.gui_addmsg = function($chatarea, data) {
	this.gui_prune_chatarea($chatarea, 100);

	var msg = this.buildMsg(data);
	if (msg.suppress_message) {
		// Message is suppressed, get out of here.
		return;
	}


	msg.msg = emojione.toImage(client.gui_subtitute_text_for_emoji(msg.msg));

	var html = this.compile_template(this.templates.message, { vars: msg }),
		shouldScroll = false;

	// we should only scroll if we are already at the bottom of the chat area (AFTER adding)
	shouldScroll = client.gui_check_should_scroll($chatarea);

	$chatarea.append(html);
	this.state.inc_message_id();

	if (shouldScroll) {
		this.gui_scroll_chatarea($chatarea);
	}

	if ($chatarea.hasClass("priv"))
	{
		if (data.mod) {
			$chatarea.parents(".privinnercontainer").attr("data-usermod", true);
		}

		this.audio.play_sound_priv_msg();
	}
	else if ((data.type === "public") && (!data.historic)) {
		if (data.target && (data.target.username == this.state.username)) {
			this.audio.play_sound_roommention();
		} else {
			this.audio.play_sound_room_msg();
		}

		var room_tab = $('#roomtabs #tab_room');
		if (!room_tab.hasClass('active')) {
			this.gui_increment_unread_badge(room_tab);
		}
	}
};

client.gui_scroll_all_message_areas = function() {
	var this_client = this;
	$(".chatarea").each(function() {
		this_client.gui_scroll_chatarea($(this));
	});
};

client.gui_scroll_chatarea = function($chatarea) {
	//$chatarea.scrollTop($chatarea.prop('scrollHeight'));
	$chatarea.scrollTop($chatarea[0].scrollHeight - $chatarea.height());
};

client.format_timestamp = function(timestamp) {
	var date = new Date(timestamp);
	return date.toTimeString().substr(0,8);
	//return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
};

client.get_linked_username = function(data) {
	return '<span class="uname" data-username="' + data.username + '" data-userid="' + data.userid + '">' + data.username + '</span>';
};

client.build_message_struct = function(data, options) {
	var result, MESSAGES = {
		_default: {
			className: 'public',
			msg: data.message,
			show_username: true,
			rich_username: true
		},
		joined: {
			className: 'joined',
			//msg: data.username + ', Welcome to the ' + data.roomname + '!',
			msg: data.username + ', Welcome to the ' + data.roomname + '!',
			show_username: false,
			system: true
		},
		welcome: {
			className: 'welcome',
			msg: data.welcometext,
			show_username: false,
			system: true
		},
		userjoin: {
			className: 'userjoin',
			msg: client.get_linked_username(data) + ' has joined the room',
			show_username: false,
			suppress_message: !options.show_partjoin,
			system: true
		},
		disconnected: {
			// TODO_CARPII: doesnt this refer to self user, in which case how could it ever appear?
			className: 'disconnected',
			msg: data.username + ' disconnected',
			system: true
		},
		leave: {
			className: 'leave',
			msg: 'left the room'
		},
		userleave: {
			className: 'userleave',
			msg: client.get_linked_username(data) + ' left the room',
			show_username: false,
			suppress_message: !options.show_partjoin,
			system: true
		},
		userquit: {
			className: 'userquit',
			msg: client.get_linked_username(data) + ' disconnected' + (data.reason ? ' (' + data.reason + ')' : ''),
			show_username: true,
			suppress_message: (!options.show_partjoin) && (!data.force),
			system: true,
			kickban: data.reason ? true : false
		},
		disconnect: {
			// TODO_CARPII: doesnt this refer to self user, in which case how could it ever appear?
			className: 'disconnect',
			msg: data.username + ' disconnected',
			system: true
		},
		friendjoin: {
			className: 'friendjoin',
			msg: 'Your friend ' + data.username + ' is now online!',
			suppress_message: !options.allow_notifications
		},
		friendquit: {
			className: 'friendquit',
			msg: 'Your friend ' + data.username + ' is now offline',
			suppress_message: !options.allow_notifications
		},
		priv: {
			className: 'priv',
			msg: data.message,
			show_username: true,
			rich_username: true
		},
		system: {
			// TODO_CARPII: When is this used?
			className: 'notify',
			msg: data.message,
			show_username: false,
			system: true
		}
	};

	result = MESSAGES._default;

	if (data && data.type && MESSAGES[data.type]) {
		// TODO_CARPII: Take a copy of this and use it to override key by key (allowing true defaults)
		result = MESSAGES[data.type];
	}

	// map over values from provided data
	result.username = data.username;
	result.color = data.colorid > 0 ? data.colorid : 0;
	result.fontbold = (data.fontbold) ? true : false;
	result.fontitalic = (data.fontitalic) ? true : false;
	result.fontid = (data.fontid > 0) ? data.fontid : 0;
	result.current = (data.username === this.state.username);
	result.show_msg_fonts = ((options) ? options.show_msg_fonts : true);
	result.msgid = this.state.message_id;
	result.targetme = false;
	result.prefix = '';

	return result;
};

client.create_anchor = function(url, label) {
	var anchor_label = label || url,
		anchor = $('<a/>').text(anchor_label).addClass('msglink');

	anchor.prop('href', url);

	return anchor[0].outerHTML;
};

client.message_preprocess_bbcodes = function(msg) {
	var regex = /\[url\](.*?)\[\/url\]/g;

	return msg.replace(regex, function(match, url) {
		var label = url;

		url = client.process_url(url);

		return client.create_anchor(url, label);
	});
};

client.buildMsg = function(data) {
	// TODO_CARPII: This is overly complex and needs refactoring
	var prefix,
		options = this.state.get_options(),
		msgData = this.build_message_struct(data, options);

	if (data.historic) {
		if (data.timestamp) {
			msgData.historic = true;
			msgData.prefix += '<span class="msgdate">[' + this.format_timestamp(data.timestamp) + ']&nbsp;</span>';
		}
	}
	else {
		// dont prefix system messages
		if (!msgData.system) {
			msgData.prefix += '<span class="msgdate">[' + this.format_timestamp(Date.now()) + "]&nbsp;</span>";
		}
	}

	msgData.me = (msgData.username === this.state.get_username());
	msgData.msg = this.message_preprocess_bbcodes(msgData.msg);

	if (msgData.system) {
		// TODO_CARPII: convert to use underscore template
		msgData.msg = '** ' + msgData.msg;
		return msgData;
	}

	var msg = this.test_message_self_action(data.message);
	if (msg !== false)
	{
		msgData.msg = '<div class="actionmessage"> ** ' + data.username + '&nbsp;' + msg + '</div>';
		msgData.show_username = false;
		return msgData;
	}

	// Prefix message with username
	if (msgData.show_username) {
		prefix = data.username;
		if (data.target) {
			// if room message directed a me, apply style so we can hilite entire message
			if (data.target.username === this.state.username) {
				msgData.targetme = true;
			}

			prefix += '<span class="rasep"> &raquo; </span>' + '<span class="uname" data-username="' + data.target.username + '" data-userid="' + data.target.userid + '">' + data.target.username + "</span>";
		}

		if (msgData.rich_username) {
			// TODO_CARPII: convert to use underscore template
			msgData.prefix += '<span class="uname" data-username="' + data.username + '" data-userid="' + data.userid + '">' + prefix + ':</span>';
		}
		else {
			msgData.prefix += (prefix + ':');
		}
	}

	return msgData;
};

client.gui_update_user_avatar = function(username, avatarid) {
	this.state.update_user_avatar_id(username, avatarid);

	// TODO_CARPII: useritems and css need converting to use data-avatarid, so we dont have to remove all css and then reapply "inlineavatar"
	var foo = $("#friends div.useritem[data-username='" + username + "'] div.inlineavatar");
	foo.removeClass().addClass("inlineavatar av-"+avatarid);
};

client.gui_add_user = function(user) {
	// TODO_CARPII: test
	user.blocked = this.state.is_user_blocked_by_username(user.username);

	var html = this.compile_template(this.templates.useritem, { vars: { user: user, show_usermeta: true, web_host: this.hosts.web, show_indicator: true }}),
		userlist = $("#online_list");

	userlist.find("div[data-userid="+user.userid+"]").remove();
	userlist.append(html);
	this.gui_update_private_message_status(user.username, false);

	// update avatars in secondary userlists
	if (user.avatarid) {
		this.gui_update_user_avatar(user.username, user.avatarid);
	}

	if (user.username === this.state.username) {
		$("#online_list").find("div[data-userid="+user.userid+"]").addClass("me viewonly");
	}

	//this.gui_sort_users();
};

client.gui_set_friend_pending_activity = function(new_state) {
	$("#userlist_tab_friends").toggleClass("pending", new_state);
};

client.gui_populate_userlist_friends = function() {
	var this_client = this,
		pending_requests = this.state.friend_list.filter(function(friend) {
		return friend.status === 'awaiting_confirmation';
	});
	this.gui_set_friend_pending_activity(pending_requests.length > 0);

	// if friends list tab visible
	if ($("div.userlist_tab[data-view=friends].usertab_active").length <= 0) {
		return;
	}

	var friendlist	= $('#friends').empty(),
		norequest = true,
		accept_count = 0;

	_.each(pending_requests, function(user) {
		// TODO_CARPII: convert to use underscore template
		if (norequest) { friendlist.append('<h2 class="requesttitle">Requests</h2>'); norequest = false; }
		var requestcontainer = $('<div class="requestcontainer" data-userid="'+user.userid+'">');

		// Show requests as non-dimmed until they're accepted
		user.pending = true;
		requestcontainer.append(this_client.compile_template(this_client.templates.useritem, { vars: { user: user, show_usermeta: false, web_host: this_client.hosts.web, show_indicator: false }}));
		requestcontainer.append(this_client.compile_template(this_client.templates.useraccept, { vars: {user: user}} ));
		friendlist.append(requestcontainer);
	});

	friendlist.append('<h2>Friends</h2>');

	var list = [].concat(this.state.friend_list);
	list.sort(function(a, b) {
		var na = a.username.toLowerCase(),
			nb = b.username.toLowerCase();

		if (a.online && !b.online) {
			return -1;
		}

		if (b.online && !a.online) {
			return 1;
		}

		if (na < nb) {
			return -1;
		}
		if (na > nb) {
			return 1;
		}
		return 0;
	});

	_.each(list, function (user) {
		if (user.status === "accepted") {
			accept_count++;
			friendlist.append(this_client.compile_template(this_client.templates.useritem, { vars: {user: user, show_usermeta: false, web_host: this_client.hosts.web, show_indicator: false }}));
		}
	});

	if (accept_count === 0) {
		friendlist.append(this_client.compile_template(this_client.templates.friendlist_empty_hint, { vars: {} }));
	}
};

client.gui_populate_userlist_blocked = function() {
	var	this_client = this;

	// if blocked list is visible tab
	// TODO_CARPII: check this is populated when tab is selected, else we shouldnt suppress it
	if ($("div.userlist_tab[data-view=blocked_list].usertab_active").length <= 0) {
		return;
	}

	var blockedlist = $('#blocked_list').empty();
	blockedlist.append('<h2>Blocked</h2>');
	_.each(this.state.block_list, function (user) {
		blockedlist.append(this_client.compile_template(this_client.templates.useritem_blocked, { vars: { user: user, show_usermeta: false, web_host: this_client.hosts.web }}));
	});

	if (this.state.block_list.length === 0) {
		blockedlist.append(this_client.compile_template(this_client.templates.blocklist_empty_hint, { vars: {} }));
	}
};

client.evaluate_sort_for_useritem = function(data) {
	var sort_value = [
			data.mod ? 'a' : 'b',
			this.state.is_user_blocked_by_username(data.username) ? 'b' : 'a',
			data.username.toUpperCase()
	].join('_');

	return sort_value;
};

client.gui_sort_users = function() {
	var sorted = $('#online_list .useritem').sort(function (a, b) {
		var contentA = client.evaluate_sort_for_useritem($(a).data());
		$(a).attr("data-sort", contentA);
		var contentB = client.evaluate_sort_for_useritem($(b).data());
		$(b).attr("data-sort", contentB);
		var result = (contentA < contentB) ? -1 : (contentA > contentB) ? 1 : 0;
		return result;
	});

	$('#online_list').find('.useritem').detach();
	$('#online_list').append(sorted);
};

client.gui_removeuser = function(username, has_quit) {
	var user = this.state.get_user_by_username(username);
	// TODO_CARPII: closing priv msg windows by right clicking on the minimised button, after which moving to diff room causes a blank priv msg window to appear for each one

	// TODO_CARPII: Chrome shows console error sometimes when user leaving (client.js:1018 Uncaught TypeError: Cannot read property 'userid' of undefined)
	// node chatbot/bots.js -c 50 -d 99999999 -f LOW -r ROOMJOIN
	// might be when first loading, and user leaves before userlist has been populated?
	// Or could be due to changing rooms somehow?

	// only remove from room list, not friends or blocked
	if ((user !== null) && (typeof user !== "undefined") && (typeof user.userid !== "undefined"))
	{
		$("div.userlist_view#online_list div.useritem[data-userid="+user.userid+"]").remove();

		if (has_quit) {
			this.gui_update_private_message_status(username, true);
		}
	}

	// clear room message if it was targetted to that user
	if ((this.public_message_target) && (username === this.public_message_target.username))
	{
		this.gui_clear_public_message_target();
	}
};

client.gui_clear_chatarea = function($chatarea) {
	$chatarea.html('');
};

client.gui_clear_modals = function() {
	$('.sysmsg').remove();
	this.gui_hide_maillist();
	this.gui_hide_news();

	$("body").trigger("chat:teardown");
};

client.gui_show_confirm = function(options, onChoose) {
	var h = this.compile_template(this.templates.confirmbox, {vars: {
		title: options.title || 'Are you sure?',
		message: options.message,
		confirm: options.confirm || 'OK',
		cancel: options.cancel || 'Cancel'
	}});

	var dlg = $(h);
	dlg.on('click', '.cbclose', function() {
		dlg.remove();
		onChoose(false);
	});
	dlg.on('click', '.cbok', function() {
		dlg.remove();
		onChoose(true);
	});

	$('body').append(dlg);
};

client.gui_show_sysmsg = function(data, options) {
	var vars = {};

	if (options) {
		vars = {
			title: options.title,
			button_text: options.button_text,
			class: options.class
		};
	}
	vars.message = data.message;

	var h = this.compile_template(this.templates.sysmsg, { vars: vars });
	$("body").append(h);
};

client.gui_clear_room_userlist = function() {
	//$("#online_list").html("");

	var userlist = $('#online_list'),
		gallerymode = userlist.parent("#userlist_views").hasClass("gallerymode"),
		markup = client.compile_template(client.templates.userlist_empty, { vars: { listmode: !gallerymode }} );

	$("#online_list").html(markup);
};

client.is_mobile_client = function() {
	var ua = window.navigator.userAgent;
	if (ua.match(/Mobi/)) {
		return true;
	}

	// 2016/11/14 - Treat Samsung TAB A as mobile
	// UA: Mozilla/5.0 (Linux; Android 6.0.1; SM-T550 Build/MMB29M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/54.0.2840.85 Safari/537.36
	if (ua.match(/Linux.*Android.*SM-.*Build/g)) {
		return true;
	}

	return false;
};

client.detect_mobile = function() {
	this.mobile_mode = this.is_mobile_client();
};

client.gui_show_chat = function() {
	this.gui_clear_chatarea($("#chatarea"));
	this.gui_clear_room_userlist();
	this.gui_disable_keyboard();
	this.gui_hide_connecting_dlg(false);
	this.gui_hide_options_dlg(false);
	this.gui_hide_roomlist(true);
	this.gui_restore_unminimised_privdialogs();

	if (client.mobile_mode && this.state.options && this.state.options.use_keyboard) {
		client.gui_toggle_keyboard();
	}

	var this_client = this;
	$("#maindiv").css("display", "block").fadeIn(function () {
		this_client.audio.play_sound_selfjoin();
	});
};

client.gui_hide_roomlist = function(instant) {
	$('#roombutton').toggleButton('release');

	this.gui_hide_generic_dlg("#roomlist", instant);
	this.gui_focus_last_chatinput();
};

client.gui_hide_news = function(instant) {
	$('#newsbutton').toggleButton('release');

	this.gui_hide_generic_dlg("#news", instant);
	this.gui_focus_last_chatinput();
};

client.gui_show_maillist = function() {
	var markup = this.compile_template(this.templates.maillist, { vars: {unread_count: this.unread_count, web_host: this.hosts.web } }),
		$dlg = $("#maillist"),
		top = $("#mailbutton").offset().top + $("#mailbutton").height(),
		left = $("#mailbutton").offset().left;

	$dlg.html(markup);
	$dlg.css({top: top, left: left}).show();
};

client.gui_hide_maillist = function() {
	$('#mailbutton').toggleButton('release');
	$("#maillist").hide();
};

client.gui_show_weblinks = function() {
	var markup = this.compile_template(this.templates.weblinks, { vars: { web_host: this.hosts.web } }),
		$dlg = $("#weblinks"),
		top = $("#weblinkbutton").offset().top + $("#weblinkbutton").height(),
		left = $("#weblinkbutton").offset().left;

	$dlg.html(markup);
	$dlg.css({top: top, left: left}).show();
};

client.gui_hide_weblinks = function() {
	$('#weblinkbutton').toggleButton('release');
	$("#weblinks").hide();
};

client.gui_reposition_roomlist = function() {
	var $roomlist = $("#roomlist");
	$roomlist.css({
		'margin-left': -Math.floor($roomlist.outerWidth() / 2),
		'margin-top': -Math.floor($roomlist.outerHeight() / 2)
	});
};

client.gui_show_privacy = function() {
	var markup = this.compile_template(this.templates.privacy, {
		vars: {
			tvcd: false,
			ts: false,
			maleadm: false,
			femaleadm: false,
			imageonly: false,
			friendsonly: false,
			privacystatus: 'available'
		}
	}),
	dlg = $("#privacy"),
	top = $("#privacybutton").offset().top + $("#privacybutton").height(),
	left = $("#privacybutton").offset().left;

	dlg.html(markup);
	dlg.css({top: top, left: left}).show();
	client.gui_init_privacy_options();
};

client.gui_hide_privacy = function() {
	$('#privacybutton').toggleButton('release');
	$("#privacy").hide();
};

client.room_peek = function(roomid) {
	// check roomlist is visible
	this.peek_roomid = roomid;

	$("#jqxpeeklistselect").jqxDropDownList({disabled: true});
	this.net.room_peek(roomid);
};

client.gui_show_roompeeked = function(data) {
	$("#jqxpeeklistselect").jqxDropDownList({disabled: false});

	// server returns list of users, with a roomid
	// if it doesnt match roomid which was most recently peeked, then discard response
	// otherwise change roomlist dialog to display list of users and thumbnails (with a back button to go back to roomlist)

	// build template for room peek
	var peeklist = $('#peeklist'),
		vars = data;

	if (data.users.length > 0) {
		var friendlist = [],
			otherlist = [];

		var usernames = client.state.friend_list.map(function(f) {
			return f.username;
		});

		data.users.forEach(function(f) {
			f.location = [f.city, f.county].filter(function(n) { return n; }).join(', ');

			if (usernames.indexOf(f.username) >= 0) {
				friendlist.push(f);
			} else {
				otherlist.push(f);
			}
		});
		
		var sortByUsername = function(a, b) {
			var ux = a.username.toLowerCase();
			var uy = b.username.toLowerCase();
			if (ux < uy) {return -1;}
			if (ux > uy) {return 1;}
			return 0;
			//return a.username > b.username;
		};

		friendlist.sort(sortByUsername);
		otherlist.sort(sortByUsername);

		peeklist.html(this.compile_template(this.templates.roomlist_peeked, {vars: {
			friends: friendlist,
			others: otherlist
		}})).fadeIn({ duration: 50 });
	} else {
		peeklist.html(this.compile_template(this.templates.roomlist_empty, {})).fadeIn({ duration: 50 });
	}
};

client.gui_show_room_list = function(data) {
	var this_client = this;

	// TODO_CARPII: Is this modifying source object?
	data.rooms = _.map(data.rooms, function (room) {
		room.current = (room.id === this_client.state.room.id);
		return room;
	});

	$("#connecting").hide();
	this.gui_hide_options(true);

	var vars = data;

	vars.show_cancel_btn = false;
	if (this.state.room.id > 0) {
		vars.show_cancel_btn = true;
	}

	if ($("#jqxroomselect").data("initialised") !== 1) {
		$("#jqxroomselect").jqxDropDownList({ source: data.rooms, displayMember: 'name', valueMember: 'id', animationType: 'none', theme: 'custom', popupZIndex: 999999 });
		$("#jqxroomselect").data("initialised", 1);
	}

	if ((this.state.room.id === 0) && (this.autojoin_room_id > 0))
	{
		this.join_room(this.autojoin_room_id);
		//this.autojoin_room_id = 0;
	}
	else
	{
		var roomlist = $('#roomlist');

		data.roomclosebutton = client.roomclosebutton;
		roomlist.html(this.compile_template(this.templates.roomlist, {vars: vars} )).fadeIn({ duration: 50 });

		client.gui_populate_peekroom_list(data.rooms);

		client.gui_reposition_roomlist();

		this.gui_bring_to_front($("#roomlist"));
	}
};

client.gui_populate_peekroom_list = function(rooms) {
	if ($("#jqxpeeklistselect").data("initialised") !== 1) {
		$("#jqxpeeklistselect").jqxDropDownList({ source: rooms, displayMember: 'name', valueMember: 'id', animationType: 'none', theme: 'custom', popupZIndex: 999999 });
		$("#jqxpeeklistselect").data("initialised", 1);
	}
};

client.gui_show_news = function(data) {
	this.gui_hide_options(true);

	var vars = {},
		body = this.compile_template(this.templates.news_skeleton, { vars: vars } ),
		content = this.compile_template(this.templates.news_content, { vars: {} } );
	$("#news").html(body);
	$("#newscontent").html(content);

	var
		dlg = $("#news"),
		top = 100,
		left = ($(document).width() / 2) - (dlg.width() / 2);

	$("#news")
		.css({top: top, left: left})
		.draggable({
			containment: "document"
		})
		.draggable("option", "cancel", "button, .container")
		.fadeIn({ duration: 50 });
	client.gui_bring_to_front($("#news"));
};

client.gui_hide_popup_menus = function(id) {
	var funcs = {
		'roombutton': client.gui_hide_roomlist,
		'optionsbutton': client.gui_hide_options,
		'mailbutton': client.gui_hide_maillist,
		'newsbutton': client.gui_hide_news,
		'weblinkbutton': client.gui_hide_weblinks,
		'privacybutton': client.gui_hide_privacy,
		'colorpickerbtn': client.gui_hide_color_picker,
		'avatarpickerbtn': client.gui_hide_avatar_picker
	};

	if (id) {
		delete funcs[id];
	}

	for (var key in funcs) {
		if (funcs.hasOwnProperty(key)) {
			funcs[key].call(client);
		}
	}

	client.gui_hide_user_popup();
	client.gui_hide_copy_popup();
};

client.gui_populate_userlist = function(users) {
	// if friends list tab visible
	// TODO_CARPII: what was the idea behind this? It causes a reconnect to not populate userlist, if freinds tab was selected
	/*if ($("div.userlist_tab[data-view=online_list].usertab_active").length <= 0) {
		return;
	}*/

	this.gui_clear_room_userlist();

	var this_client = this;
	$.each(users, function(k, v) {
		this_client.gui_add_user(v);
		this_client.gui_update_privacy_indicator(v.username, this_client.state.profilegroup, v.privacy);
	});
};

client.gui_hide_connecting_dlg = function(instant) {
	this.gui_hide_generic_dlg("#connecting", instant);
};

client.gui_hide_options_dlg = function(instant) {
	this.gui_hide_generic_dlg("#options", instant);
	this.gui_focus_last_chatinput();
};

client.gui_hide_generic_dlg = function(id, instant) {
	if (instant) {
		$(id).hide();
	}
	else {
		$(id).fadeOut(50);
	}
};

client.begin_login = function() {
	this.gui_show_dialog("Please Wait.\n\nLogging into tvChix Chatrooms...");
	$("#connecting").show();

	this.net.login(this.state.username, this.state.token, this.state.challenge, this.version, this.build);
};

client.nl2br  = function(str) {
	return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
};

client.gui_show_dialog = function(msg) {
	msg = this.nl2br(msg);
	$("#connecting").html(msg).show();
	this.gui_hide_roomlist(true);
	this.gui_hide_options_dlg(true);
	this.gui_hide_privacy();
	this.gui_hide_weblinks();
	$("#maindiv, div.privdialog").hide();
	$('#jqxroomselect').jqxDropDownList('close')
};

client.decode_user_privacy = function(user) {
	user.privacy = client.decode_privacy_bits(user.privacy);

	return user;
};

client.decode_privacy_bits = function(bits) {
	return {
		tvcd: (bits & 1) > 0,
		ts: (bits & 2) > 0,
		maleadm: (bits & 4) > 0,
		femaleadm: (bits & 8) > 0,
		imageonly: (bits & 16) > 0,
		friendsonly: (bits & 32) > 0,
	};
};

client.gui_show_system_notification = function(message) {
	var msg = { message: message, type: "system" };
	this.gui_addmsg(this.gui_get_target_room_chatarea(), msg);
};

client.gui_init_options = function() {
	var options  = this.state.get_options();
	this.options_temp = options;

	if (options !== null)
	{
		$("#opt_showpartjoin").prop('checked', options.show_partjoin);
		$("#opt_showtimestamps").prop('checked', options.show_timestamps);
		$("#opt_showroommsghilite").prop('checked', options.show_roommsghilite);
		$("#opt_confirmlinknav").prop('checked', options.ask_nav_away);
		$("#opt_usetabs").prop('checked', options.use_tabs);
		$("#use_keyboard").prop('checked', options.use_keyboard);
		$("#opt_allownotifications").prop('checked', options.allow_notifications);
		$("#show_msgcolors").prop('checked', options.show_msg_colors);
		$("#show_msgfonts").prop('checked', options.show_msg_fonts);
		$("#show_avatars").prop('checked', options.show_avatars);
		$("#show_smileys").prop('checked', options.show_smileys);
		$("#show_smileys").prop('disabled', true);
		$("label[for='show_smileys']").addClass('label_disabled');

		$("#opt_key_esc_closes").prop('checked', options.key_esc_closes);
		$("#opt_key_esc_minimises").prop('checked', !options.key_esc_closes);
		$("#opt_hide_blocked_userlist").prop("checked", options.hide_blocked_userlist);
		$("#opt_hide_blocked_chat").prop("checked", options.hide_blocked_chat);

		$("#soundmute").jqxCheckBox({checked: options.global_mute});

		var fontval = $("#opt_chatfont option[data-fontid='" + options.fontid + "']").val();
		$("#opt_chatfont").val(fontval);

		var fontsize = $("#opt_chatfontsize option[data-fontsize='" + options.fontsize + "']").val();
		$("#opt_chatfontsize").val(fontsize);
		this.gui_scroll_all_message_areas();
	}

	soundMapDelta = {};
};

client.gui_init_privacy_options = function() {
	var privacy_options = this.state.get_privacy_options();
	// TODO_CARPII: Add back in with availability dropdown
	//privacystatus = this.state.get_privacy_status() || client.default_privacy_status;

	['tvcd', 'ts', 'maleadm', 'femaleadm'].forEach(function(opt) {
		var selector = '#privacy_' + opt,
			val = privacy_options[opt] ? true : false;

		if ($(selector).length > 0) {
			$(selector).jqxCheckBox({checked: val});
		}
	});

	// TODO_CARPII: Add back in with availability dropdown
	//$('#privacystatus').val(privacystatus);
};

client.gui_show_options = function() {
	this.gui_init_options();

	client.gui_options_reset_sound_dropdowns();

	var dlg = $("#options"),
		top = 100,
		left = ($(document).width() / 2) - (dlg.width() / 2);

	$("#options")
		.css({top: top, left: left})
		.draggable({
			containment: "document"
		})
		.draggable("option", "cancel", ".tabs, .tab_container, input, button, submit")
		.fadeIn({ duration: 50 });

	this.gui_bring_to_front($("#options"));
};

client.gui_click_userlist_button = function() {
	$('#userlist').toggle();
	$(this).find('.button').toggleClass('pressed');
};

client.gui_create_privdialog = function(username, content, userinitiated) {
	var this_client = this,
		dlg_markup = this.compile_template(this.templates.privdialog, { vars: { } }),
		dlg = $(dlg_markup);
		//$input_focus = $("input.chatinput:focus");

	dlg.data('username', username);
	content.css("display", "block");
	dlg.append(content);
	dlg.draggable({
			containment: "document",
			handle: '.header',
			stop: function(e) { this_client.gui_on_dialog_dragging_stop(e); }
		})
		.resizable({ autoHide: true, minHeight: 140, minWidth: 200 })
		.css({position: "absolute", top: 10, left: 10});

	$("body").append(dlg);

	var chatinput = dlg.find('input.chatinput');

	client.init_emojipicker(chatinput, client.send_priv_input_and_clear.bind(client));

	if (this.state.get_option('use_tabs') || this.mobile_mode) {
		this.gui_tabify_priv_window(dlg.find("div.ptabify"), userinitiated);
	}
	else
	{
		// TODO_CARPII: change this to return initial postion, and call it before setting absolute position via css above
		this.gui_position_initial_dialog(dlg);
		dlg.show();

		// if this dialog wasnt opened by user, then try to reset inout focus to room or priv msg
		if (!userinitiated) {
			client.gui_focus_last_chatinput();
		}
	}
};

client.get_emojipicker = function(elem) {
	if (!(elem instanceof jQuery)) {
		elem = $(elem);
	}

	if (isMobileDevice()) {
		return {
			hidePicker: function() {
				//noop
			},

			showPicker: function() {
				//noop
			},

			getText: function() {
				return elem.val();
			},

			setText: function(val) {
				return elem.val(val);
			},

			setFocus: function() {
				elem.focus();
			},

			hasClass: function(cls) {
				return elem.hasClass(cls);
			}
		};
	}

	return elem.data('emojioneArea');
};

client.init_emojipicker = function(elem, cb) {
	if (isMobileDevice()) {
		return;
	}

	if (!(elem instanceof jQuery)) {
		elem = $(elem);
	}

	if (elem.data('emojioneArea')) {
		return;
	}

	elem.emojioneArea({
		hidePickerOnBlur: false,

		textcomplete: {
			maxCount: 5,
		},
		events: {
			// Enable this to disable picker after one emoji is selected.
			/*emojibtn_click: function(btn, evt) {
				var picker = elem.data('emojioneArea');
				picker.hidePicker();
			},*/

			focus: function(editor, evt) {
				if (client.last_emoji_editor.length <= 0) {
					//console.log('emojione.focus() - evt.target... SETTING FIRST last_emoji_editor');
					//console.log($(evt.target));
					client.last_emoji_editor = $(evt.target);
					return;
				}

				if ($(evt.target)[0] !== client.last_emoji_editor[0]) {
					//console.log('emojione.focus() - evt.target... SETTING last_emoji_editor');
					//console.log($(evt.target));
					client.last_emoji_editor = $(evt.target);
				}

				client.gui_set_last_chatinput(editor, true);
			},

			blur: function(editor, evt) {
				//console.log('emojione.blur() - evt.target...');
				//console.log($(evt.target));

				/*if ($(evt.target).hasClass('emojionearea-editor')) {
					// record current picker so we can close it on a focus event elsewhere
					client.last_emoji_editor = $(evt.target);

					console.log('blur - set last_emoji_editor');
					return;
				}

				var picker = client.get_emojipicker($(editor).parents('.chatinput:first').siblings('input.chatinput'));

				if (picker) {
					console.log('blur - found picker, closing');
					picker.hidePicker();
				}
				else
				{
					console.log('blur - not found picker');
				}*/
			},

			/*emojibtn_click: function(btn, evt) {
				console.log(btn.children().data('name'));
			},*/

			keyup: function(editor, evt) {
				if (evt.keyCode === scancodes.F2) {
					var picker = elem.data('emojioneArea');

					if (picker.picker.hasClass('hidden')) {
						picker.showPicker();
					} else {
						picker.hidePicker();
					}
				}

				if (evt.keyCode === scancodes.ENTER)
				{
					if (client.justSelectedInlineEmoji) {
						client.justSelectedInlineEmoji = false;
						return;
					}

					cb(elem);
					evt.stopPropagation();
				}
			}
		}
	});

	// workaround for IE11 hiding picker when using scrollbars (IE focuses body, then scrollarea, causing picker to get a blur event)
	var area = elem.data("emojioneArea");
	if ($(area).scrollArea !== undefined){
		$(area).scrollArea.on("focus", function(evt) { elem.data("emojioneArea").showPicker(); });
	}
	//elem.data("emojioneArea").scrollArea.on("focus", function(evt) { elem.data("emojioneArea").showPicker(); });
};

client.gui_get_target_input = function() {
	// if theres a private window with 'hasfocus'
	if ($(".privdialog.hasfocus").length > 0) {
		return $(".privdialog.hasfocus").find("input.chatinput.priv");
	}

	// else if the selected tab is a priv chat or main chat area
	var active_tab = $("#roomtabs li.active");
	if (active_tab.length > 0)
	{
		var tab_id = active_tab.find("span").data("tabname");
		var tab_container = $("#" + tab_id);
		if (tab_container.length > 0)
		{
			var chatinput = tab_container.find("input.chatinput.priv");
			if (chatinput.length > 0) {
				return chatinput;
			}
			else
			{
				return $("#chatinput");
			}
		}
	}

	return null;
};

client.gui_find_priv_inner_container = function(username) {
	return $('.privinnercontainer[data-username="' + username + '"]');
};

client.gui_get_priv_target = function(username, userinitiated) {
	var container = client.gui_find_priv_inner_container(username);

	if (container.length === 0) {
		// create new container
		var new_priv_container = this.gui_create_privcontainer(username);

		// create dialog wrapper for container
		// TODO: Add option to auto tabify incoming privs instead of dialogs
		this.gui_create_privdialog(username, new_priv_container, userinitiated);

		container = client.gui_find_priv_inner_container(username);
	}

	var $container = $(container);
	if ($container.hasClass('privtabified')) {
		var private_tab = $('ul#roomtabs li#tab_priv_' + username);
		if (userinitiated) {
			this.gui_select_tab(private_tab, container);
			client.gui_scroll_active_tab_to_view();
		} else if (!private_tab.hasClass('active')) {
			this.gui_increment_unread_badge(private_tab);
		}
	} else if ($container.hasClass('privminimised')) {
		var maxbutton = $('.minimised[data-username="' + username + '"]');
		if (maxbutton.length !== 0) {
			container = this.gui_get_priv_container_for_button(maxbutton);

			// minimised dialog found, either show it or just update badge
			if (userinitiated) {
				this.gui_unminimise_priv_window(maxbutton);
			} else {
				this.gui_increment_unread_badge(maxbutton);
			}
		}
	} else if (container.closest('.privdialog').length > 0) {
		// TODO: Check if we actually need this. if its not user initiated, then we dont necessarily want dialog coming to front anyway
		container.closest('.privdialog').show();
	}

	if (userinitiated) {
		client.gui_focus_input($('.privcontainer[data-username="' + username + '"] .chatinput.priv'));
	}

	return container;
};

client.gui_create_privcontainer = function(username, innercontainer) {
	var markup = this.compile_template(this.templates.privcontainer, {vars: {username : username }}),
		innermarkup = this.compile_template(this.templates.privinnercontainer, { vars: {username : username, unminimise: !this.state.get_option('use_tabs') }});

	if (typeof innercontainer !== "undefined") {
		markup = $(markup).append(innercontainer);
	}
	else {
		markup = $(markup).append(innermarkup);
	}

	return $(markup);
};

client.gui_show_priv = function(username, userinitiated, data) {
	var $privdlg, $privinner = this.gui_get_priv_target(username, userinitiated);
	var u = client.search_user_by_username(username);

	if (typeof data !== "undefined")
	{
		var $parent = $privinner.find(".chatarea.priv").first();
		this.gui_addmsg_priv($parent, data);
		$parent.scrollTop = $parent.scrollHeight;
	}

	if (typeof u !== "undefined") {
		$privinner.data('profilegroup', u.profilegroup);
		if (u.mod === true) {
			$privinner.attr("data-usermod", true);
		}
	}

	// if were using an existing dialog, then bring to front and focus
	if (userinitiated)
	{
		$privdlg = $privinner.closest(".privdialog");
		if ($privdlg.length > 0) {
			this.gui_focus_privdialog($privdlg);
		}
	}
};

client.search_user_by_username = function(username) {
	var user;

	user = client.state.get_user_by_username(username);
	if (user) {
		return user;
	}

	return client.state.get_friend_by_username(username);
};

client.gui_hide_options = function(instant) {
	if (typeof instant === "undefined") {
		instant = true;
	}
	$('#optionsbutton').toggleButton('release');
	this.gui_hide_options_dlg(instant);
};

client.gui_cancel_options = function() {
	this.state.set_options(this.options_temp);
	this.gui_apply_options(this.options_temp);
	this.gui_hide_options(false);
	soundMapDelta = {};
};

client.gui_restore_unminimised_privdialogs = function() {
	var minimised_dialogs = $('#minimisedarea [data-username]').map(function() {
		return $(this).data('username');
	}).get();

	$('.privdialog').each(function() {
		var dlg = $(this);

		if (minimised_dialogs.indexOf(dlg.data('username')) < 0) {
			dlg.show();
		}
	});
};

client.gui_apply_font_defaults = function(data) {
	$("#fontpicker").jqxDropDownList({selectedIndex: data.fontid });

	//$("#fontpicker option[data-fontid="+data.fontid+"]").prop('selected', true);
	this.fontid = data.fontid;

	if (data.fontbold) {
		this.gui_toggle_fontbold($("#boldbutton"), data.fontbold);
	}

	if (data.fontitalic) {
		this.gui_toggle_fontitalic($("#italicbutton"), data.fontitalic);
	}

	if (data.fontcolor > 0) {
		this.gui_set_message_color(data.fontcolor);
	}
};

client.gui_apply_options = function(options) {
	if (options === null)
	{
		options = {};
	}

	if (options) {
		if (options.hasOwnProperty('fontid')) {
			this.gui_select_font_id(options.fontid);
		}
		if (options.hasOwnProperty('fontsize')) {
			this.gui_select_font_size(options.fontsize);
		}
		if (options.hasOwnProperty('avatarid')) {
			this.gui_select_avatar(options.avatarid);
		}

		this.gui_apply_opt_showmsg_colors(options.show_msg_colors);
		this.gui_apply_opt_showmsg_fonts(options.show_msg_fonts);
		this.gui_apply_opt_roomhilite(options.show_roommsghilite);

		$('body').toggleClass('hide_avatars', !options.show_avatars);
		$('.privbanner .unminimise').toggleClass('hidden', options.use_tabs);
		$('div#userlist').toggleClass('hide_blocked_from_userlist', ((options.hide_blocked_userlist === true) || (options.hide_blocked_userlist === 1)));
		$('body').toggleClass('hide_blocked_chat', ((options.hide_blocked_chat === true) || (options.hide_blocked_chat === 1)));

		$('#soundmute').jqxCheckBox({checked: ((options.global_mute === true) || (options.global_mute === 1))});

		this.gui_apply_opt_showmsg_timestamps(options.show_timestamps);
	}

	this.state.set_options(options);
	this.options_temp = null;
};

client.gui_save_privacy_options = function() {
	var options = {},
		privacystatus;

	// TODO_CARPII: Add back in with availability dropdown
	// privacystatus = $('#privacystatus').val();
	$('#privacy_settings input[type=checkbox]').each(function(idx, opt) {
		options[$(opt).data('privacy')] = $(opt).jqxCheckBox('checked');
	});

	this.state.set_privacy_options(privacystatus, options);
};

client.gui_save_options = function() {
	var newopt = {};

	newopt.show_partjoin = $("#opt_showpartjoin").prop('checked');
	newopt.show_timestamps = $("#opt_showtimestamps").prop('checked');
	newopt.show_roommsghilite = $("#opt_showroommsghilite").prop('checked');
	newopt.ask_nav_away = $("#opt_confirmlinknav").prop('checked');
	newopt.use_tabs = $("#opt_usetabs").prop('checked');
	newopt.use_keyboard = $("#use_keyboard").prop('checked');
	newopt.allow_notifications = $('#opt_allownotifications').prop("checked");
	newopt.show_msg_colors = $("#show_msgcolors").prop('checked');
	newopt.show_msg_fonts = $("#show_msgfonts").prop('checked');
	newopt.show_avatars = $("#show_avatars").prop('checked');
	newopt.show_smileys = $("#show_smileys").prop('checked');
	newopt.fontid = $("#opt_chatfont :selected").data("fontid");
	newopt.fontsize = $("#opt_chatfontsize :selected").data("fontsize");
	newopt.avatarid = $("#avatar_preview").data("avatarid");
	newopt.key_esc_closes = $('#opt_key_esc_closes').is(':checked');

	newopt.hide_blocked_userlist = $("#opt_hide_blocked_userlist").is(":checked");
	newopt.hide_blocked_chat = $("#opt_hide_blocked_chat").is(":checked");

	newopt.global_mute = $('#soundmute').jqxCheckBox('checked') ? true : false;

	for (var k in soundMapDelta) {
		if (soundMapDelta.hasOwnProperty(k)) {
			client.audio.set_user_sound(k, soundMapDelta[k]);
		}
	}

	this.state.set_usersounds(client.audio.get_user_sounds());

	this.state.set_options(newopt);
	this.gui_apply_options(newopt);
};

client.join_room = function(roomid) {
	// this will rejoin the same room you are already in (causing a desync issue), unless protected by caller
	this.net.join_room(roomid);
};

client.generate_keyboard = function(type) {
	var markup = this.templates.get_keyboard_markup(type);
	$('#keyboard').html(markup);
};

client.get_selected_popup_username = function() {
	return $('#userpopup').data('username');
};

client.set_selected_popup_username = function(username) {
	$('#userpopup').data('username', username);
};

client.gui_show_user_popup = function(item, e) {
	var popupExtraClasses = [];

	client.gui_hide_popup_menus();

	// toggle routine to hide popup if already visible
	var $popup = $("#userpopup");
	if ($popup.css("display") === "block") {
		this.gui_hide_user_popup();
		return;
	}

	var username = this.get_selected_popup_username();
	if (typeof username === "undefined") {
		return;
	}

	// check online users
	var	targetuser = this.state.get_user_by_username(username);

	// check offline friends
	if ((!targetuser) || (typeof targetuser === "undefined")) {
		targetuser = this.state.get_friend_by_username(username);

		if (targetuser && targetuser.online) {
			popupExtraClasses.push('user-outofchan');
		} else {
			popupExtraClasses.push('user-offline');
		}
	}

	// check offline blocked users
	if ((!targetuser) || (typeof targetuser === "undefined")) {
		targetuser = this.state.get_blocked_by_username(username);
		if (targetuser) {
			popupExtraClasses.push('user-offline');
		}
	}

	// try to popup anything instead of doing nothing
	if ((!targetuser) || (typeof targetuser === "undefined")) {
		popupExtraClasses.push('user-offline');
		targetuser = {
			username: username
		};
	}

	var view = item.parent('.userlist_view').attr('id');

	var href = "//" + client.hosts.web + "/profiles/" + targetuser.username,
		isme = (targetuser.username === this.state.username),
		blocked = this.state.is_user_blocked_by_username(targetuser.username),
		available = this.state.current_user_can_contact_user(targetuser.username) || this.state.is_friend(targetuser.username),
		friend = this.state.get_friend_by_username(targetuser.username),
		isfriend = (friend && friend.status === 'accepted'),
		isrequestsent = (friend && friend.status === 'requested'),
		isrequestrcvd = (friend && friend.status === 'awaiting_confirmation'),
		isroomtarget = ((typeof this.public_message_target !== "undefined") && (this.public_message_target !== null) && (this.public_message_target.username === targetuser.username));

	$("#menulink_profile").attr("href", href);

	$popup
		.removeClass()
		.removeAttr("style")
		.addClass("popup");

	if (typeof view !== "undefined") {
		/*.removeClass("request-sent request-received friend blocked me view_online_list view_blocked_list view_friends")*/
		$popup.addClass('view_' + view);
	}

	if (popupExtraClasses.length > 0) {
		$popup.addClass(popupExtraClasses.join(' '));
	}

	$("#userpopup .header .label").text(targetuser.username);

	client.gui_reposition_user_popup(e, item, $popup);

	$popup
		.data("username", targetuser.username)
		.data("userid", targetuser.userid)
		.data("gender", targetuser.gender)
		.data("usertype", targetuser.usertype);

	if (isme) {
		$popup.addClass("viewonly");
	} else {
		$popup.toggleClass("blocked", blocked);
		$popup.toggleClass("available", available);
		$popup.toggleClass("friend", isfriend);
		$popup.toggleClass("request-sent", isrequestsent);
		$popup.toggleClass("room-target", isroomtarget);
		$popup.toggleClass("request-received", isrequestrcvd);
	}

	// add a pseudo-disabled class to items which are visible but greyed out
	$("#menulink_priv").toggleClass('menuinert', blocked);
	$("#menulink_nopriv").toggleClass('menuinert', !available);
	$("#menulink_add_friend").toggleClass('menuinert', blocked);
	$("#menulink_personal_room_message").toggleClass('menuinert', blocked);

	$("#menu_profile a")
		.addClass("external")
		.attr({ target: "_blank" });

	$popup.css("display", "block");
	$popup.show();

	// 2016/10/13.PAC - bring to front as it could underlap priv msg
	var maxz = client.gui_get_next_zindex();
	$popup.css("z-index", maxz);
};

client.gui_reposition_user_popup = function(e, item, popup) {
	/*
		Positioning of the popup
		If the click was on the left side of the screen, the popup will appear
		on the right side.
		If the click was on the bottom side of the screen, the popup will appear above
		the mouse cursor.
	*/
	var height = $(document).height();
	var width = $(document).width();
	var destx = e.pageX,
		desty = e.pageY,
		heightOffset = height;
	if (item.hasClass('useritem') && width > 525) {
		// On a larger screen set the popup position
		// to just on the outside of the clicked on row
		var offset = item.offset();
		destx = offset.left;
		desty = offset.top;
		heightOffset -= item.height();
	}

	var toporbottom = desty < (height/2) ? 'top' : 'bottom';
	var offsetY = desty < (height/2) ? desty : (heightOffset - desty);

	var leftorright = destx < (width/2) ? "left" : "right";
	var offsetX = destx < (width/2) ? destx : (width - destx);

	popup
		.css('left', 'auto')
		.css('right', 'auto')
		.css('top', 'auto')
		.css('bottom', 'auto')
		.css(leftorright, offsetX)
		.css(toporbottom, offsetY);
};

client.gui_hide_user_popup = function() {
	$("#userpopup").fadeOut({duration: 200});
};

client.gui_hide_copy_popup = function(show_copied) {
	if (show_copied) {
		client.gui_set_selection("");
		$("#copypopup").data("popup_closing", true);
		$("#copypopup .menuitem")
			.text("Copied Selected Text");
		setTimeout(function(){ client.gui_hide_copy_popup(false); }, 1000);
	}
	else {
		$("#copypopup").fadeOut({duration: 200});
	}
};

client.gui_select_font_id = function(fontid) {
	var fam = $("#opt_chatfont option[data-fontid='" + fontid + "']");
	var family = fam.data("family");
	if (fam) {
		$("body").css("font-family", family);
	}
};

client.gui_select_font_size = function(fontsize) {
	$("body").removeClass("zoom0 zoom1 zoom2 zoom3").addClass("zoom"+fontsize);
	this.gui_scroll_all_message_areas();
};

client.gui_apply_opt_showmsg_colors = function(opt) {
	$('body').toggleClass('opt_colors', (opt === true) || (opt === 1));
};

client.gui_apply_opt_showmsg_fonts = function(opt) {
	$('body').toggleClass('opt_fonts', (opt === true) || (opt === 1));
};

client.gui_apply_opt_roomhilite = function(opt) {
	$('body').toggleClass('room_hilite', opt);
};

client.gui_apply_opt_showmsg_timestamps = function(opt) {
	$('body').toggleClass('show_timestamps', ((opt === true) || (opt === 1)));
};

client.gui_select_avatar = function(id) {
	var previd = $("#avatar_preview").data("avatarid");
	if (previd > 0) {
		$("#avatar_preview").removeClass("av-" + previd);
	}

	$("#avatar_preview").data("avatarid", id).addClass("av-" + id);

	$("#avatarpicker .avatar.selected").removeClass("selected");
	$("#avatarpicker .avatar[data-avatarid='"+id+"']").addClass("selected");
	$('#userlist .me .inlineavatar').removeClass().addClass('inlineavatar av-' + id);
};

client.gui_update_avatars = function(username, avatarid) {
	this.gui_update_user_avatar(username, avatarid);

	// update main userlist
	var elem = $('#userlist .useritem[data-username=' + username + '] .inlineavatar');
	elem.removeClass().addClass('inlineavatar av-' + avatarid);
};

client.gui_focus_clearall = function() {
	$(".privdialog").removeClass("hasfocus");
};

client.gui_focus_privdialog = function(dlg) {
	// if dialog (rather than tabified)
	if (dlg.hasClass("dialog") && dlg.hasClass("privdialog"))
	{
		this.gui_focus_clearall();
		dlg.addClass("hasfocus");
		this.gui_bring_to_front(dlg);
	}

	client.gui_focus_input(dlg.find("input.chatinput.priv"));
};

client.gui_focus_next_available_privdialog = function() {
	var dlg = $(".dialog.privdialog:visible:last");
	if (dlg.length > 0) {
		this.gui_focus_privdialog(dlg);
	}
	else {
		// TODO_CARPII: This needs to fcus chatinput on selected tab, not necessarily #chatinput
		this.gui_focus_main_chatarea();
	}
};

client.gui_focus_main_chatarea = function() {
	this.gui_focus_clearall();
	this.gui_focus_chat_input();
};

client.gui_focus_input = function(input) {
	if (input) {
		var picker = client.get_emojipicker(input);
		if (picker) {
			picker.setFocus();
		}
	}
};

client.gui_focus_chat_input = function() {
	client.gui_focus_input($('#chatinput'));
};

client.gui_get_next_zindex = function() {
	/*var maxz = 0;
	$(".dialog").each(function() {
		if (($(this).css("z-index") !== "auto") &&
			($(this).css("z-index") > maxz)) {
			maxz = parseInt($(this).css("z-index"), 10);
		}
	});*/

	if (this.zindex < 100) {
		this.zindex = 100;
	}

	return ++this.zindex;
};

client.gui_bring_to_front = function(dlg) {
	if (!dlg.hasClass('dialog')) {
		return;
	}

	var maxz = client.gui_get_next_zindex();
	dlg.css("z-index", maxz);
	return true;
};

client.block_user = function(username, userid) {
	this.net.block_user_by_username(username);
};

client.gui_close_all_privmsg_windows = function() {
	var targets = $('.privinnercontainer[data-username]');

	for (var i = 0; i < targets.length; i++) {
		var username = $(targets[i]).data('username');
		client.gui_close_privmsg_windows_for_username(username);
	}
};

client.get_user_meta = function(username) {
	this.net.get_user_meta_packet(username);
};

client.gui_close_privmsg_windows_for_username = function(username) {
	var user = client.state.get_user_by_username(username),
		privinner = $(".privinnercontainer[data-username='"+username+"']");

	var btn = client.gui_get_minimised_button_for_username(username);
	if (btn.length > 0) {
		client.gui_close_minimised_priv(username);
	}

	var dlg = privinner.parents(".dialog");
	if (dlg.length > 0) {
		client.gui_close_privwindow(dlg, true);
	}

	if (privinner.length > 0) {
		client.gui_close_tab_which_contains(privinner, true);
	}
};

client.gui_block_user_confirm = function(username) {
	var user = client.state.get_user_by_username(username);

	if (user) {
		client.gui_show_confirm({
			title: 'Block user',
			message: 'Are you sure you want to block ' + user.username + '?',
			confirm: 'Block',
			cancel: 'Cancel'
		}, function(response) {
			if (response) {
				client.block_user(username);
				client.gui_block_user(user);

				client.gui_close_privmsg_windows_for_username(username);
			}
		});
	}
};

client.gui_block_user = function(user) {
	var userid = user.userid,
		username = user.username;

	if (!this.state.is_user_blocked_by_username(username)) {
		$('[data-userid="' + userid + '"]').addClass('blocked');

		this.state.add_block(user);
		this.state.remove_friend(userid);
		this.remove_friend(userid);
	}
};

client.unblock_user = function (username, userid) {
	// TODO_CARPII: can we remove userid from these now they accept username?
	this.net.unblock_user(username, userid);
	this.state.remove_block(username, userid);
	this.gui_unblock_user(username, userid);
};

client.gui_unblock_user = function (username, userid) {
	// TODO_CARPII: Needs passing username
	$('[data-userid="' + userid + '"]').removeClass('blocked');
	this.gui_populate_userlist_blocked();
	this.gui_sort_users();
};

client.gui_get_minimised_button_for_username = function(username) {
	return $('div#minimisedarea button.minimised[data-username="' + username + '"]');
};

client.gui_update_private_message_status = function(username, disabled) {
	var inner = $('.privinnercontainer[data-username="' + username + '"]'),
		tab = $('.tabs #tab_priv_' + username),
		$minbtn = this.gui_get_minimised_button_for_username(username);

	if (inner.length > 0) {
		inner.toggleClass('privinert', disabled);
		inner.closest(".privcontainer").toggleClass('privinert', disabled);
	}

	if ($minbtn.length > 0) {
		$minbtn.toggleClass('btninert', disabled);
	}

	if (tab.length > 0) {
		tab.toggleClass('offline-tab', disabled);
	}
};

client.gui_disable_keyboard = function() {
	$('#maindiv').removeClass('keyboardenabled');

	$('input[type="text"]').each(function(idx, elem) {
		var input = $(elem);

		if (!input.data('emojioneArea')) {
			input.show();
		}
	});

	$('.inputreplacement').remove();
	$('#keyboardbutton div.button.pressed').removeClass('pressed');
	$('#keyboard').hide();
	this.gui_focus_chat_input();
};

client.apply_mobile_keyboard_to_chatarea = function() {
	$('input.chatinput').hide();

	// TODO_CARPII: convert to use underscore template
	// create input replacement, and assign color
	$('<div class="inputreplacement">')
		.insertAfter('input.chatinput');

	// set color of input replacements (for room tabs only)
	$('input.chatinput:not(.priv) + div.inputreplacement').css("color", this.color_rgb);

	// copy text from chat inputs into input replacements
	$("input.chatinput + div.inputreplacement").each(function() {
		$(this).text($(this).prev("input.chatinput").val());
	});
};

client.gui_enable_keyboard = function() {
	$('#maindiv').addClass('keyboardenabled');

	this.apply_mobile_keyboard_to_chatarea();

	$('#keyboardbutton').find('div.button').addClass('pressed');

	this.generate_keyboard();
	$('#keyboard').show();
	this.gui_move_all_dialogs_to_within_bounds();
};

client.is_keyboard_visible = function() {
	return ($('#maindiv').hasClass('keyboardenabled'));
};

client.gui_toggle_keyboard = function() {
	if (this.is_keyboard_visible()) {
		this.gui_disable_keyboard();
	} else {
		this.gui_enable_keyboard();
		$(".chatarea").scrollTop($('.chatarea')[0].scrollHeight);
	}
};

client.gui_update_mobile_state = function(button) {
	var childBtn = button.children().first();
	$('body').toggleClass('mobile', this.mobile_mode);
	childBtn.toggleClass('pressed', this.mobile_mode);
};

client.gui_update_userlistbtn_state = function(button) {
	var childBtn = button.children().first();
	childBtn.toggleClass('pressed');
};

client.gui_toggle_mobile = function(button) {
	var childBtn = button.children().first();
	this.mobile_mode = !childBtn.hasClass('pressed');
	this.gui_update_mobile_state(button);
	this.gui_focus_last_chatinput();
};

client.gui_toggle_userlist_btn = function(button) {
	//var childBtn = button.children().first();
	this.gui_update_userlistbtn_state(button);
	this.gui_focus_last_chatinput();
};

client.gui_close_userlist_popup = function() {
	var userListButton = $("#userlistbutton");

	if (userListButton.is(":visible")) {
		$("#userlist").hide();
		userListButton.find(".button").removeClass("pressed");
	}
	this.gui_focus_last_chatinput();
};

client.gui_update_font = function(select) {
	//var fid = select.find(":selected").data("fontid");
	var fid = select.jqxDropDownList('selectedIndex');

	// NOTE: can access the original #fontpicker select via element..
	// $('#fontpicker').data().jqxDropDownList.element[0].dataset

	if (fid !== this.fontid)
	{
		this.fontid = fid;
		for (var i=0; i<12; i++) {
			$(".chatinput").removeClass("font" + i);
		}
		$(".chatinput").addClass("font" + fid);
	}
};

client.gui_toggle_fontbold = function(button, newval) {
	if (typeof(newval) === "undefined") {
		newval = !this.fontbold;
	}

	button.toggleClass('pressed', newval);
	$(".chatinput").toggleClass('fontbold', newval);
	//this.gui_get_input_replacements().toggleClass('fontbold');
	this.fontbold = newval;
};

client.gui_toggle_fontitalic = function(button, newval) {
	if (typeof(newval) === "undefined") {
		newval = !this.fontitalic;
	}

	button.toggleClass('pressed', newval);
	$(".chatinput").toggleClass('fontitalic', newval);
	//this.gui_get_input_replacements().toggleClass('fontitalic');
	this.fontitalic = newval;
};

client.build_avatar_picker = function() {
	$('#avatarpicker').html(this.compile_template(this.templates.avatarpicker));
};

client.build_color_picker = function() {
	$('#colorpicker').html(this.compile_template(this.templates.colorpicker));
};

client.gui_toggle_userinput_button = function(popup) {
	var maxz = client.gui_get_next_zindex(),
		popupHeight = popup.outerHeight(),
		input_header = $("#inputheader")[0].getBoundingClientRect(),
		input_container = $("#inputcontainer")[0].getBoundingClientRect();

	popup.css({"z-index": maxz, "top": (input_container.top - popupHeight + input_header.height) + "px"}).toggle();
};

client.gui_toggle_color_picker = function() {
	client.gui_hide_popup_menus('colorpickerbtn');
	client.gui_toggle_userinput_button($('#colorpicker'));
};

client.gui_hide_color_picker = function() {
	$('#colorpicker').hide();
};

client.gui_hide_avatar_picker = function() {
	$('#avatarpicker').hide();
};

client.gui_append_friend_to_friendlist = function (friendobj) {
	this.gui_populate_userlist_friends();
};

client.decline_friendship = function(userid) {
	this.net.decline_friendship(userid);
	this.state.friend_list = _.reject(client.state.friend_list, function(friend) { return friend.userid === userid; });
	this.gui_remove_request_from_friendlist(userid);
};

client.gui_remove_request_from_friendlist = function(userid) {
	this.gui_populate_userlist_friends();
};

client.remove_friend = function(userid) {
	this.state.remove_friend(userid);
	this.gui_populate_userlist_friends();
};

client.remove_friend_by_username = function(username) {
	this.state.remove_friend_by_username(username);
	this.gui_populate_userlist_friends();
};

client.add_friend = function(data) {
	// TODO: validate object fields
	this.state.add_friend(data.username, data.userid, data.status);
	this.gui_populate_userlist_friends();
};

client.gui_remove_friend_from_friendlist = function(username) {
	client.remove_friend_by_username(username);
	client.gui_populate_userlist_friends();

	var exfriend = client.state.get_user_by_username(username);
	if (exfriend) {
		client.gui_update_privacy_indicator(exfriend.username, client.state.profilegroup, exfriend.privacy);
	}
};

client.gui_set_public_message_target = function(data) {
	var data_copy = {username: data.username, userid: data.userid, gender: data.gender, usertype: data.usertype};
	this.public_message_target = data_copy;

	var pm = $('#public_personal_message');
	pm.html("Room Messages &raquo; " + data.username + " [X]");
	pm.addClass('visible');
	pm.css("color", "blue");
	pm.animate({color: '#000'}, 1000);
};

client.gui_clear_public_message_target = function() {
	this.public_message_target = null;
	$('#public_personal_message').text("").removeClass('visible');
};

client.on_userquit = function(payload) {
		var type = 'userquit',
			username = payload.username,
			username_data = { username : username };

		client.gui_update_private_message_status(username, true);

		if (this.state.is_username_in_current_room(username)) {
			var force = false,
				reason = null;

			switch (payload.type) {
				case 'kick':
					force = true;
					reason = 'kicked by ' + payload.actioned_by;
					break;

				case 'ban':
					force = true;
					reason = 'banned by ' + payload.actioned_by;
					break;
			}
			client.audio.play_sound_userquit();
			client.gui_addmsg(client.gui_get_target_room_chatarea(), {
				type: type,
				username: username,
				reason: reason,
				force: force
			});
		}
		client.gui_removeuser(username, true);

		client.state.digest(type, username_data);
		client.set_friend_offline(username_data, true);
		client.gui_set_room_title(client.state);
};

client.gui_set_selection = function(txt) {
	this.selection = txt;
};

client.gui_get_selection = function() {
	return this.selection;
};

client.getSelectionText = function() {
	var text = "";
	var activeEl = document.activeElement;

	var activeElTagName = activeEl ? activeEl.tagName.toLowerCase() : null;
	if ((activeElTagName === "textarea" || activeElTagName === "input") &&
			/^(?:text|search|password|tel|url)$/i.test(activeEl.type) &&
			(typeof activeEl.selectionStart === "number")) {
		text = activeEl.value.slice(activeEl.selectionStart, activeEl.selectionEnd);
	} else if (window.getSelection) {
		text = window.getSelection().toString();
	}

	return client.sanitiseSelectedText(text);
};

client.sanitiseSelectedText = function(text) {
	/*

	2017/01/25 - this tries to avoid selecting outside priv message window, by returning only text AFTER the last blank line
	needs further testing on Windows, especially related to the \n vs \r\n shit

	how this has been left: Works ok on OSX, but for Windows the selection is copied as one multiline string
	Due to all the tickets on the go at the mo, Ive had had to push this live anyway, to get SSL live
	*/

	//console.log("sanitising");
	var i,
		res = text.split(/\r\n|\r|\n/g),
		len = res.length,
		line,
		filtered = "";

	/*
	console.log("full log (" + len + " lines)");
	console.log("----------");
	for (i=0; i<len; i++) {
		console.log(res[i]);
	}

	console.log("partial log (" + len + " lines)");
	console.log("----------");
	*/

	for (i=len-1; i>=0; i--) {
		line = res[i];
		// working backwards, abort after we find first blank line
		line = line.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
		if (line === "")
		{
			//console.log("found blank line, aborting");
			break;
		}

		//console.log("adding line: " + line);
		line = line.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
		filtered = line + '\n' + filtered;
	}

	/*
	res = filtered.split("/\r\n|\r|\n/g");
	len = res.length;

	console.log("outputting filtered lines (" + len + " lines)");
	console.log("----------");
	for (i=0; i<len; i++) {
		console.log('[' + res[i] + ']');
	}*/

	text = filtered;

	return text;
};

$(document).ready(function() {
	client.detect_mobile();

	if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
		client.firefox_workaround = true;
	}

	$(document).on('textComplete:show', function(evt) {
		var elem = $('.dropdown-menu.textcomplete-dropdown');

		elem.css({'transform': 'translateY(-100%) translateX(10px)'});
	}).on('textComplete:select', function(evt) {
		client.justSelectedInlineEmoji = true;
	});

	new Clipboard('#copypopup', {
		text: function(trigger) {
			// get selected text from chatarea
			// return lines as a CR delimited string
			return client.gui_get_selection();
		}
	});

	$("body").on('mouseup', '.chatarea', function(e) {
		var selection = client.getSelectionText();

		if (selection.toString() !== "")
		{
			client.gui_set_selection(selection);
		}
	});

	client.gui_update_mobile_state($('#mobilebutton'));
	client.net = new Net(client);

	$("#fontpicker").jqxDropDownList({ width: 160, height: 23, theme: 'custom', animationType: 'none', enableBrowserBoundsDetection: true, popupZIndex: 999999,  });

	if ((typeof(chat_username) === "undefined") || (typeof(chat_request_token) === "undefined") || (typeof(chat_web_host) === "undefined") || (typeof(chat_thumbs_host) === "undefined") || (typeof(chat_images_host) === "undefined"))
	{
		if (typeof(chat_username) === "undefined") {
			client.gui_show_dialog("You must be signed into tvChix to use our Chatrooms\n\n<a class='signin' href='//" + client.hosts.web + "/login.php'>Sign into tvChix</a>");
		}
		else {
			client.gui_show_dialog("Your chat session has expired.\n\nPlease access the chatrooms from the tvChix website...\n\n<a class='signin' href='//" + client.hosts.web + "/newchat.php'>&laquo; Back to tvChix Chatroom page</a>");
		}
		return;
	}

	client.hosts = {
		images: chat_images_host,
		thumbs: chat_thumbs_host,
		web: chat_web_host,
	};

	client.state = new State();
	client.state.set_login(chat_username, chat_request_token);

	// TODO_CARPII: <<----- do this for dev mode only (how? client has no devmode toggle yet)
	var url_params = client.parse_username_from_url();
	if (url_params) {
		if (url_params.autojoin_room_id) {
			client.autojoin_room_id = url_params.autojoin_room_id;
		}

		if (url_params.username) {
			client.state.set_login(url_params.username, chat_request_token);
		}
	}
	// ----->>

	client.build_popup();
	client.build_avatar_picker();
	client.build_color_picker();
	client.audio = new ClientAudio(client);

	$(window).resize(function() {
		client.gui_move_all_dialogs_to_within_bounds();
		client.gui_toggle_next_prev_tab_buttons();
		client.gui_scroll_active_tab_to_view();
		client.gui_update_minimised_area();

		// generic reposition all sysdlgs
		client.gui_reposition_roomlist();
		//client.gui_reposition_sysdialog($("#news"));
	});

	$("#newsbutton").toggleButton()
		.on("chat:togglebutton:down", function(evt) {
			client.gui_show_news();
		})
		.on("chat:togglebutton:up", function(evt) {
			client.gui_hide_news();
		})
	;

	$("#roombutton").toggleButton()
		.on("chat:togglebutton:down", function(evt) {
			client.net.request_room_list();
			// TODO_CARPII: WTF?
			client.roomclosebutton = true;
		})
		.on("chat:togglebutton:up", function(evt) {
			client.gui_hide_roomlist(false);
		})
	;

	$("#optionsbutton").toggleButton()
		.on("chat:togglebutton:down", function(evt) {
			client.gui_show_options();
		})
		.on("chat:togglebutton:up", function(evt) {
			client.gui_hide_options();
		})
	;

	$("#mailbutton").toggleButton()
		.on("chat:togglebutton:down", function(evt) {
			client.gui_show_maillist();
			client.gui_focus_last_chatinput();
		})
		.on("chat:togglebutton:up", function(evt) {
			client.gui_hide_maillist();
			client.gui_focus_last_chatinput();
		})
	;

	$("#weblinkbutton").toggleButton()
		.on("chat:togglebutton:down", function(evt) {
			client.gui_show_weblinks();
			client.gui_focus_last_chatinput();
		})
		.on("chat:togglebutton:up", function(evt) {
			client.gui_hide_weblinks();
			client.gui_focus_last_chatinput();
		})
	;

	$("#privacybutton").toggleButton()
		.on("chat:togglebutton:down", function(evt) {
			client.gui_show_privacy();
			client.gui_focus_last_chatinput();
		})
		.on("chat:togglebutton:up", function(evt) {
			client.gui_hide_privacy();
			client.gui_focus_last_chatinput();
		})
	;

	var softKeyboardTapEvent = ('ontouchstart' in window ? 'touchstart' : 'click');

	$(document)
	.on("mousedown touchstart", null, function(e) {
		// 2017/09/24 - could this be the source of focus problems, now that body tag z-index was altered?
		if ($(e.target).parents(".privdialog").length <= 0) {
			client.gui_focus_clearall();
		}
	})
	.on("click", "#btn_reload", function(e) {
		var cur_url = window.location.href,
			parts = cur_url.split("/"),
			result = "//" + parts[2] + "/" + $(this).data("version") + "/";

		window.location.href = result;
	})
	.on("click", "#peeklist .user", function(e) {
		var username = $(this).data("username"),
			href = "//" + client.hosts.web + "/profiles/" + username;

		var $popup = $("#userpopup");
		if ($popup.css("display") === "block") {
			client.gui_hide_user_popup();
			return;
		}


		$popup.data("username", username);
		$("#userpopup .header .label").text(username);
		$("#menulink_profile").attr("href", href);

		client.gui_reposition_user_popup(e, $(this), $popup);

		$("#menu_profile a")
			.addClass("external")
			.attr({ target: "_blank" });

		$popup
			.addClass("viewonly")
			.css("display", "block")
			.show();

		var maxz = client.gui_get_next_zindex();
		$popup.css("z-index", maxz);
	})
	.on("contextmenu", ".chatarea", function(e) {
		client.gui_hide_popup_menus();

		if (client.gui_get_selection().toString() === "")
		{
			client.getSelectionText();
		}

		if (client.gui_get_selection().toString() !== "") {
			$("#copypopup .menuitem").text("Copy");
			$("#copypopup")
				.finish()
				.data("popup_closing", false)
				.css("top", (e.pageY-10) + "px")
				.css("left", (e.pageX-10) + "px")
				.show()
				.css("z-index", client.gui_get_next_zindex());
		}
		return false;
	})
	.on("contextmenu", "body", function(e){
		return false;
	})
	.on('click', '#keyboardbutton', function() {
		client.gui_toggle_keyboard();
	})
	.on('blur', 'input.chatinput', function() {
		client.gui_set_last_chatinput($(this), false);
	})
	.on('focus', 'input.chatinput', function() {
		client.gui_set_last_chatinput($(this), true);
	})
	.on('click', '#tab-left', function() {
		client.gui_select_prev_tab();
	})
	.on('click', '#min-button-left', function() {
		client.gui_scroll_minimised_area_left();
	})
	.on('click', '#min-button-right', function() {
		client.gui_scroll_minimised_area_right();
	})
	.on('click', '#tab-right', function() {
		client.gui_select_next_tab();
	})
	.on('click', '#mobilebutton', function() {
		client.gui_toggle_mobile($(this));
	})
	.on('chat:togglebutton:up', function(evt) {
		// noop
	})
	.on('chat:togglebutton:down', function(evt) {
		$('.buttonblock').not(evt.target).toggleButton('release');
		client.gui_hide_popup_menus(evt.target.id);
	})
	.on('click mouseup touchend', '#maillist a.mailbox', function(e) {
		client.gui_hide_maillist();
	})
	.on('click mouseup touchend', '#weblinks .weblink', function(e) {
		client.gui_hide_weblinks();
	})
	.on('click', '#userlistbutton', function() {
		client.gui_toggle_userlist_btn($(this));
	})
	.on('click', '#boldbutton', function() {
		client.gui_toggle_fontbold($(this));
		client.gui_focus_input($(this).closest(".inputcontainer").find(".chatinput input.chatinput"));
	})
	.on('click', '#italicbutton', function() {
		client.gui_toggle_fontitalic($(this));
		client.gui_focus_input($(this).closest(".inputcontainer").find(".chatinput input.chatinput"));
	})
	.on('click', '.msglink', function(evt) {
		//suppress dialog for ctrl-clicked links
		if ((evt.ctrlKey) || (evt.metaKey)) {
			return;
		}

		if (client.state.get_option('ask_nav_away')) {
			evt.preventDefault();

			var src = $(this).prop('href'),
				text = src.substring(0, 120);

			if (text.length < src.length) {
				text += '...';
			}

			client.gui_show_sysmsg({
				message: client.compile_template(client.templates.sysmsg_navaway, {vars: {
					link: src,
					linkText: text,
					mobile_mode: client.mobile_mode
				}})
			}, {
				title: 'Leave tvChix Chat?',
				button_text: 'Cancel',
				class: 'navaway'
			});
		}
	})
	.on('mousedown touchstart', '.key, #keyboard', function(e) {
		return false;
	})
	.on('contextmenu', '.minimised', function() {
		if ($(this).hasClass('new-message')) {
			$(this).click();
		}
		else {
			var username = $(this).data('username');
			client.gui_close_minimised_priv(username);
		}
	})
	.on("change", "ul#roomtabs li", function() {
		client.gui_relocate_minimised_area($(this));
		client.gui_scroll_active_tab_to_view();
		client.gui_scroll_all_message_areas();
		var input = client.gui_get_active_chatinput();
		if (input.length > 0) {
			client.gui_focus_chat_input(input);
		}
	})
	.on("contextmenu", "ul#roomtabs li", function(e) {
		var $childspan = $(this).find("span");
		if ($childspan.hasClass("privtab")) {
			close_tab($childspan);
		}
	})
	.on("change", "#fontpicker", function() {
		client.gui_update_font($(this));
		setTimeout(function() { client.gui_focus_last_chatinput(); }, 10);
		//$(this).closest(".inputcontainer").find(".chatinput input.chatinput").focus();
	})
	/*.on("change", "select#fontpicker", function() {
		client.gui_update_font($(this));
		$(this).closest(".inputcontainer").find(".chatinput input.chatinput").focus();
	})*/
	.on("close", "ul#roomtabs li", function(e, data) {
		var newparent = client.gui_get_target_room_chatarea();
		if (data.next_tab.length > 0) {
			newparent = data.next_tab;
		}

		client.gui_relocate_minimised_area(newparent);
		client.gui_toggle_next_prev_tab_buttons();

		// TODO_CARPII: do we select a new tab here, or does tabset fire an onchange event?
	})
	.on(softKeyboardTapEvent, '.key', function() {
		var value = $(this).data('key'),
			input = client.gui_get_target_input(),
			replacement = $(input).next('.inputreplacement');
		if (input === null) {
			return;
		}

		var editor = $(input);
		if (!editor) {
			return;
		}

		var
			inputval = editor.val(),
			shiftkey = $('.key[data-key="⇧"]');

		// for keys 0-9, they will be retrieved as integers, so need to convert back
		if ((typeof value === 'number') && (value.toString().length === 1)) {
			value = value.toString();
		}

		if (value === "←") {
			var newval = inputval.substr(0, inputval.length-1);
			editor.val(newval);
			replacement.text(newval);
			return;
		}
		if (value === "⇧") {
			$(this).toggleClass('uppercase');
			return;
		}
		if (value === "space") {
			value = " ";
		}
		if (value === "↩") {
			client.audio.play_sound_keyclick_send();
			$(input).closest(".chatinputcontainer").find('.chatsend').click();
			return;
		}
		if (value === 123) {
			client.generate_keyboard('numbers');
			return;
		}
		if (value === "ABC") {
			client.generate_keyboard();
			return;
		}
		if (value === "#+=") {
			client.generate_keyboard('special');
			return;
		}
		if (!shiftkey.hasClass('uppercase')) {
			value = value.toLowerCase();
		}
		else {
			shiftkey.removeClass('uppercase');
		}

		client.audio.play_sound_keyclick();
		var newval2 = inputval + value;

		editor.val(newval2);
		replacement.text(newval2);
		// Updating the scrollLeft with the current width of the element.
		// This will follow the current scroll postion
		replacement.scrollLeft(replacement.width());
	})
	.on("mousedown touchstart", ".dialog", function(e){
		if ($(this).is('.ui-draggable-dragging')) {
			return;
		}

		client.gui_focus_privdialog($(this));
		client.gui_bring_to_front($(this));
	})
	.on("keyup touchend", '#chatinput', function(e) {
		/*
			Keys that get no reaction: backspace, shift, arrow keys
		*/
		// TODO: This all needs looking at, value has scoping issues and some regexp work
		var invalidKeys = [8, 16, 37, 38, 39, 30],
			validKeys = [9];

		if (_.contains(invalidKeys, e.keyCode) || !_.contains(validKeys, e.keyCode)) {
			return;
		}
	})
	.on("change", "#options #opt_chatfont", function(e) {
		var fontid = $(this).find(":selected").data("fontid");
		client.gui_select_font_id(fontid);
	})
	.on("change", "#options #opt_chatfontsize", function(e) {
		var fontsize = $(this).find(":selected").data("fontsize");
		client.gui_select_font_size(fontsize);
	})
	.on("change", "#options #show_msgcolors", function(e) {
		var c=$(this).prop("checked");
		client.gui_apply_opt_showmsg_colors(c);
	})
	.on("change", "#options #opt_showtimestamps", function(e) {
		var c=$(this).prop("checked");
		client.gui_apply_opt_showmsg_timestamps(c);
	})
	.on("change", "#options #opt_showroommsghilite", function(e) {
		var c=$(this).prop("checked");
		client.gui_apply_opt_roomhilite(c);
	})
	.on("click", "div#userlist div.presentation div", function(e) {
		var userlist_views = $(this).parents("div#userlist_views");

		$(this).siblings().removeClass("selected");
		$(this).addClass("selected");

		if ($("div#userlist_views div.presentation div.list.selected").length > 0) {
			userlist_views.removeClass("gallerymode");
		}
		else
		{
			userlist_views.addClass("gallerymode");
		}
	})
	.on("click", "a.useritem_uname", function(e) {
		e.preventDefault();
	})
	.on("click", "div.sysmsg.navaway a.link", function(e) {
		// close dialog when CTRL/CMD left clicking navaway confirmation links
		var this_sysmsg = $(this).parents(".sysmsg");
		if ((e.which === 1) && ((e.ctrlKey) || (e.metaKey))) {
			client.gui_close_sysdlg_deferred(this_sysmsg);
		}
	})
	.on("mouseup touchend", "div.sysmsg.navaway a.link", function(e) {
		// close dialog when middle clicking navaway confirmation links
		var this_sysmsg = $(this).parents(".sysmsg");
		if (e.which === 2) {
			client.gui_close_sysdlg_deferred(this_sysmsg);
		}
	})
	.on("change", "#options #show_msgfonts", function(e) {
		var c=$(this).prop("checked");
		client.gui_apply_opt_showmsg_fonts(c);
	})
	.on("click", ".useritem .uname_privbtn", function(e) {
		e.stopPropagation();

		var $item = $(this).parent('.useritem');
		var user_id = $item.data('userid');
		var user = client.state.get_user(user_id);

		if (!user) {
			user = client.state.get_friend(user_id);
		}

		if (user && user.username) {
			client.gui_show_priv(user.username, true);
		}
	})
	.on("click", "div.useritem, .uname", function(e) {
		if (!$(this).hasClass("viewonly"))
		{
			//e.preventDefault();
			client.set_selected_popup_username($(this).data('username'));
			client.gui_show_user_popup($(this), e);
		}
		e.stopPropagation();
	})
	.on("mouseleave", "#userpopup.popup", function() {
		if (!client.mobile_mode) {
			client.gui_hide_user_popup();
		}
		// TODO: NORC - needed? client.gui_focus_last_chatinput();
	})
	.on("mouseleave", "#copypopup.popup", function() {
		if (!client.mobile_mode) {
			if ($("#copypopup").data("popup_closing")) {
				return;
			}
			client.gui_hide_copy_popup();
		}
	})
	.on("click", "#userpopup .header .close", function() {
		client.gui_hide_user_popup();
	})
	.on("click", "#userpopup div.menuitem", function() {
		if ($(this).parents('a:first').hasClass('menuinert')) {
			return;
		}

		client.gui_hide_user_popup();
	})
	.on("click", "#copypopup div.menuitem", function() {
		client.gui_hide_copy_popup(true);
	})
	.on("click", "#menulink_profile", function(e) {
		client.gui_close_userlist_popup();
	})
	.on("click", "#menulink_priv div.menuitem", function(e) {
		if ($("#menulink_priv").hasClass("menuinert")) {
			return false;
		}

		var username = $("#userpopup").data("username");
		client.gui_show_priv(username, true);

		if ($('#userlistbutton .button').is(':visible')) {
			client.gui_click_userlist_button();
		}
	})
	.on("click", "#public_personal_message", function() {
		client.gui_clear_public_message_target();
		client.gui_focus_chat_input();
	})
	.on("click", "#menulink_personal_room_message", function(e) {
		client.gui_set_public_message_target($('#userpopup').data());
		client.gui_close_userlist_popup();
		/*if ($('#userlistbutton .button').is(':visible')) {
			client.gui_click_userlist_button();
		}*/
	})
	.on("click", "#menulink_clear_room_message", function(e) {
		client.gui_clear_public_message_target();
		client.gui_close_userlist_popup();
		/*if ($('#userlistbutton .button').is(':visible')) {
			client.gui_click_userlist_button();
		}*/
	})
	.on("keydown", ".chatinput.priv", function (e) {
		if (e.keyCode === scancodes.ENTER) {
			if (!$(this).parents('.privinnercontainer').hasClass('privinert')) {
				client.send_priv_input_and_clear($(this));
			}
			e.stopPropagation();
		}
	})
	.on("keyup", function (e) {
		if (e.keyCode === scancodes.ESC) {
			client.gui_respond_to_ESC();
		}
	})
	.on("click", "#optcancel", function(e) {
		client.gui_cancel_options();
	})
	.on("click", "#userlistbutton", function() {
		client.gui_click_userlist_button();
	})
	.on("click", "#optsave", function(e) {
		client.gui_save_options();
		client.net.save_options();
		client.net.save_usersounds();
		client.gui_hide_options(false);
	})
	.on("click", "#btnprivacysave", function() {
		client.gui_save_privacy_options();
		client.net.save_privacy_options();
		client.gui_hide_privacy();
	})
	.on("click", "#logoutbutton", function() {
		client.gui_show_confirm({
			title: 'Quit?',
			message: 'Are you sure you want to quit tvChix Chat?',
			confirm: 'Quit',
			cancel: 'Cancel'
		}, function(response) {
			if (response) {
				client.gui_close_all_privmsg_windows();
				client.disconnect_reason = "You have disconnected from tvChix Chat<br><br><a href='//" + client.hosts.web + "'>&laquo; Back to tvChix</a>";
				client.disconnect_reason_iscomplete = true;
				client.socket.disconnect();
			}
		});
	})
	.on("click", "#roombutton", function() {
		if ($('#roombutton div.button.pressed').length > 0)
		{
			client.net.request_room_list();
		}
		else {
			client.gui_hide_roomlist(false);
		}
	})
	.on("touchend", "#userlist", function() {
		// 2016/10/06 - disable sorting deferral as it seems to fuck up on Firefox
		/*client.defer_userlist_sort = true;
		clearTimeout(client.deferTimeout);
		client.deferTimeout = setTimeout(function() {
			client.defer_userlist_sort = false;
			client.gui_sort_users();
		}, 10000);*/
		client.gui_sort_users();
	})
	.on("click", "div.pminimise", function() {
		client.gui_minimise_priv_window($(this));
	})
	.on("click", "div.ptabify", function() {
		client.gui_tabify_priv_window($(this), true);
	})
	.on("click", "div.pclose", function() {
		client.gui_close_privwindow($(this).parents(".privdialog:first"));
	})
	.on("click", ".privbanner .block", function() {
		var dlg = $(this).parents(".privinnercontainer:first");
		client.gui_block_user_confirm(dlg.data('username'));
	})
	.on("click", "div.pblock", function() {
		var dlg = $(this).parents(".privdialog:first");
		client.gui_block_user_confirm(dlg.data('username'));
	})
	.on("click", "button#roomcancel", function () {
		client.gui_hide_roomlist(false);
	})
	.on("click", "button#roompeekcancel", function () {
		client.net.room_list();
	})
	.on("click", "#newsclose", function () {
		client.gui_hide_news(false);
	})
	.on("click", "#roombackbutton", function () {
		window.location.href = "//" + client.hosts.web;
	})
	.on("click", "#optionsbutton", function() {
		if ($("#optionsbutton div.button.pressed").length > 0)
		{
			client.gui_show_options();
		}
		else
		{
			client.gui_cancel_options();
		}
	})
	.on("click", "#roompreview", function(e) {
		e.stopPropagation();
		$("#roomlist .roomlist").toggleClass("hidden", true);
		$("#roomlist .peeklist").toggleClass("hidden", false);

		$("#jqxpeeklistselect").jqxDropDownList('selectIndex', 0);
	})
	.on("click", "#roomback", function(e) {
		e.stopPropagation();
		$("#roomlist .roomlist").toggleClass("hidden", false);
		$("#roomlist .peeklist").toggleClass("hidden", true);
	})
	.on("click", ".roomitem", function(){
		// TODO_CARPII: this will need rewriting when we allow users to join multiple rooms
		var roomid = $(this).data("roomid");
		if (!$(this).hasClass("currentroom")) {
			if (!client.join_in_progress) {
				client.join_in_progress = true;
				client.join_room(roomid);
			}
		}
		else {
			client.gui_hide_roomlist(false);
		}
	})
	.on("unselect", "#jqxroomselect", function (e) {
		var args = e.args;
		var item = $(this).jqxDropDownList('getItem', args.index);

		if (item) {
			var roomid = item.value;
			client.previous_room_item = item;
		}
	})
	.on("select", "#jqxroomselect", function (e) {
		if ($(this).data("ignoreselect") === 1) {
			return;
		}

		var args = e.args;
		var item = $(this).jqxDropDownList('getItem', args.index);
		if (typeof(item) !== "undefined") {
			var roomid = item.value;
			client.join_room(roomid);
		}
		//e.stopPropagation();
		//return false;
	})
	.on("select", "#jqxpeeklistselect", function (e) {
		var item = $("#jqxpeeklistselect").jqxDropDownList('getSelectedItem');

		if (item) {
			client.room_peek(item.value);
		}
	})
	.on("click", "#roompeekjoin", function(e) {
		var item = $("#jqxpeeklistselect").jqxDropDownList('getSelectedItem');

		if (typeof(item) !== "undefined") {
			var roomid = item.value;
			
			// 2020/11/20 - do not try to rejoin the same room you are already in (results in detached state)
			if (client.state.room.id !== roomid) {
				client.join_room(roomid);
			}
			else {
				client.gui_hide_roomlist(true);
			}
		}
	})
	.on("click", "div.chatsend.priv", function(e) {
		// TODO_CARPII: review parent selector
		var $privinput = $(this).parent().find("input.chatinput.priv");
		client.send_priv_input_and_clear($privinput);
		e.stopPropagation();
	})
	.on("click", "div.chatsend:not(.priv)", function(e) {
		// TODO_CARPII: when this targets an inert priv dlg, it still sends whereas ENTER key doesnt
		var $chatinput = $(this).siblings("div.chatinput").find("input.chatinput");
		client.send_input_and_clear($chatinput);
		e.stopPropagation();
	})
	.on("keydown", "input.chatinput:not(.priv)", function (e) {
		if (e.keyCode === scancodes.ENTER)
	{
		// TODO_CARPII: Previously id based, needs making more generic and support privmsg too
		client.send_input_and_clear($(this));
		e.stopPropagation();
	}
	})
	.on("click", '#menulink_unblock .menuitem', function() {
		var userid = $('#userpopup').data('userid');
		var username = $('#userpopup').data('username');
		client.unblock_user(username, userid);
	})
	.on("click", '#show_avatars', function() {
		if ($(this).prop('checked')) {
			$('body').removeClass('hide_avatars');
		}
		else {
			$('body').addClass('hide_avatars');
		}
	})
	.on("click", '#menulink_block .menuitem', function (e) {
		var username = $('#userpopup').data('username');
		client.gui_block_user_confirm(username);
		e.preventDefault();
	})
	.on("click", '#menulink_add_friend', function() {
		// TODO_CARPII: cant we just filter this out with a :not selector ?
		if ($('#menulink_add_friend').hasClass("menuinert")) {
			return false;
		}

		var userid = $('#userpopup').data('userid');
		var username = $('#userpopup').data('username');

		// Check not already added
		if (!client.state.get_friend(userid)) {
			// TODO_CARPII: This needs to call into client.add_freind, maybe needs adding more params though
			client.net.add_friend(userid);
			client.state.add_friend(username, userid, "requested");
		}
	})
	.on("click", '#menulink_confirm_friend', function() {
		var userid = $('#userpopup').data('userid');

		client.net.accept_friendship(userid);

		/*
			The friend is already in the client.state.friend_list array.
			Just change the status to accepted and the change will be reflected
			on the next friend related action.
		*/
		var friendobj = null;
		client.state.friend_list = _.map(client.state.friend_list, function (friend) {
			if (friend.userid === userid) {
				friend.status = 'accepted';
				friendobj = friend;
			}
			return friend;
		});
		/*
			Now remove the request container from the DOM
			and append the friend object to the list.
		*/
		client.gui_append_friend_to_friendlist(friendobj);
		client.gui_focus_chat_input();
	})
	.on("click", '#menulink_remove_friend', function() {
		var userid = $('#userpopup').data('userid'),
			username = $('#userpopup').data('username');

		client.net.remove_friend(userid);
		client.gui_remove_friend_from_friendlist(username);
		client.gui_focus_chat_input();
	})
	.on("click", "#colorpickerbtn", function() {
		client.gui_toggle_color_picker();
	})
	.on("mouseleave", "#colorpicker", function() {
		$('#colorpicker').hide();
	})
	.on("click", "#colorpicker", function() {
		$('#colorpicker').hide();
		client.gui_focus_last_chatinput();
	})
	.on("click", "#colorpicker .color", function() {
		var colorid = $(this).data("colorid");
		client.gui_set_message_color(colorid);
		client.gui_focus_last_chatinput();
	})
	.on("click", "#header_username, #avatar_container", function(e) {
		var btn = $('#avatarpickerbtn'),
			btnRect = btn[0].getBoundingClientRect(),
			picker = $('#avatarpicker'),
			top = btnRect.bottom + 5,
			right = 5;

		picker.css({top: top, right: right});
		client.gui_hide_popup_menus('avatarpickerbtn');
		picker.toggle();
		client.gui_focus_last_chatinput();
	})
	.on("mouseleave", "#avatarpicker", function() {
		$('#avatarpicker').hide();
		client.gui_focus_last_chatinput();
	})
	.on("click", "#avatarpicker	div.avatar", function() {
		var id = $(this).data("avatarid");
		if (id >= 0)
		{
			var options = client.state.get_options();
			options.avatarid = id;

			client.state.set_options(options);
			client.gui_select_avatar(id);
			$('.useritem[data-username="'+ client.state.username +'"]').find('.inlineavatar').removeClass(_.map(_.range(0,16), function(a,b) { return 'av-'+b; }).join(" ")).addClass('av-' + id);

		}
		$('#avatarpicker').hide();
		client.gui_save_options();
		client.gui_focus_last_chatinput();
	})
	.on("click", ".sclose", function() {
		client.gui_close_sysdlg($(this).parents(".sysmsg"));
	})
	.on("click", ".userlist_tab", function() {
		client.gui_hide_user_popup();
		$('.userlist_view').hide();
		$('.userlist_tab').removeClass('usertab_active');
		$(this).addClass('usertab_active');
		$('#' + $(this).data('view')).show();
		client.gui_focus_last_chatinput();
	})
	.on("click", ".userlist_tab[data-view='blocked_list']", function() {
		client.gui_populate_userlist_blocked();
	})
	.on("click", ".userlist_tab[data-view='friends']", function() {
		client.gui_populate_userlist_friends();
	})
	.on("click", '.friend_controls div.friendbutton.accept', function() {
		var userid = parseFloat($(this).data('userid'));

		client.net.accept_friendship(userid);

		/*
			The friend is already in the client.state.friend_list array.
			Just change the status to accepted and the change will be reflected
			on the next friend related action.
		*/
		var friendobj = null;
		client.state.friend_list = _.map(client.state.friend_list, function (friend) {
			if (friend.userid === userid) {
				friend.status = 'accepted';
				friendobj = friend;
			}
			return friend;
		});

		/*
			Now remove the request container from the DOM.
		*/
		client.gui_append_friend_to_friendlist(friendobj);
	})
	.on("click", '.friend_controls div.friendbutton.decline', function() {
		var userid = parseFloat($(this).data('userid'));
		client.decline_friendship(userid);
	})
	// 2016/10/06 - disable sorting deferral as it seems to fuck up on Firefox
	/*.on("mouseenter", "#online_list", function() {
		client.defer_userlist_sort = true;
	})*/
	.on("click", "div.privbanner div.close", function() {
		client.gui_close_tab_which_contains($(this));
	})
	.on("click", "div.privbanner div.unminimise", function() {
		var container = $(this).closest(".privinnercontainer");
		client.gui_untabify_priv_window(container);
		client.gui_focus_last_chatinput();
	})
	.on("mousedown touchstart", "div.emojionearea-editor", function(e) {
		var elem = e.target;
		if (client.firefox_workaround) {
			if ($(elem).is(":focus")) {
				e.preventDefault();
			}
		}
	});
	// 2016/10/06 - disable sorting deferral as it seems to fuck up on Firefox
	/*.on("mouseleave", "#online_list", function() {
		if (client.defer_userlist_sort) {
			client.defer_userlist_sort = false;
			client.gui_sort_users();
		}
	});*/

	$("body")
		//.on("click", ".emojionearea-button-close", function(evt) {
			//console.log('close');
			//client.last_opened_emojipicker = null;
		//})
		.on("click", ".emojionearea-button", function(evt) {
			evt.stopPropagation();
			/*
			console.log('open');

			if (client.last_opened_emojipicker) {
				console.log(client.last_opened_emojipicker);

				var picker = client.get_emojipicker(client.last_opened_emojipicker);

				if (picker) {
					picker.hidePicker();
				}
			}

			client.last_opened_emojipicker = $(evt.target).parents('.chatinput:first').siblings('input.chatinput:first');
			*/
		})
		.on("click", "div#userlist, div.chatarea, div.chatinput", function(evt) {
			client.gui_hide_user_popup();
			client.gui_hide_maillist();
			client.gui_hide_privacy();
			client.gui_hide_weblinks();

			//var target = $(evt.target);

			//$('.chatinput').each(function(idx, elem) {
				//var picker = client.get_emojipicker($(elem).find('input.chatinput'));
				//if (picker) {
					//picker.hidePicker();
				//}
			//});

			////var shithole = target.parents('.chatinput:first').siblings('input.chatinput:first');

			////console.log(
				////client.gui_get_target_input()
			////);
			//[>
			//if (
				//(target.parents('.emojionearea-picker').length > 0) ||
				//(target.parents('.emojionearea-button').length > 0)
			//) {
				//return;
			//}
			//*/

			//[>
			//var container = target.parents('.privcontainer:first');
			//if (container.length === 0) {
				//container = target.parents('.chatinputcontainer:first');
			//}

			//console.log(container);
			//*/

			//var picker = client.get_emojipicker(client.gui_get_target_input());
			////console.log(client.gui_get_target_input());

			//if (picker) {
				////console.log(picker);
				////picker.hidePicker();
			//}
		});

	client.gui_show_dialog("Connecting to tvChix Chatrooms...");

	// TODO_CARPII: Added timeout param although have not yet got connect_timeout to fire
	var delays = client.get_reconnection_delays();
	var socketOptions = {
		'path': '/socket.io/',
		'reconnection': true,
		'reconnectionDelay': delays.reconnectionDelay * 1000,
		'reconnectionDelayMax': delays.reconnectionDelayMax * 1000,
		'reconnectionAttempts': client.max_reconnect_tries,
		'timeout': (10 * 1000)
	};

	if (client.websockets_only) {
		socketOptions.transports = ['websocket'];
		socketOptions.upgrade = false;
	}

	client.socket = io.connect('', socketOptions);

	client.socket.on('connect', function (data) {
		client.on_connect();
		client.successful_connect = true;
	});

	client.socket.on('plugin', function (data) {
		$.getScript(data.url);
	});

	client.socket.on('load_css', function (data) {
		var urls = data.urls;
		_.each(urls, function (url) {
			$('head').append('<link rel="stylesheet" href="' + url + '" />');
		});
	});

	client.socket.on('connect_timeout', function () {
		// TODO_CARPII: unknown purpose and have not got it to fire yet
		// probably results when firewalled or cant even resolve chat server. Needs testing to see how client reacts

		/*
		var now = new Date();
		console.log(now.toUTCString() + " - socket.io reconnection timeout");
		*/
	});

	client.socket.on('reconnecting', function () {
		var attempts = Math.max(1, ++client.reconnect_tries);

		var dlgtext = (client.successful_connect ? 'Reconnecting' : 'Connecting');
		dlgtext += ' to chat (Attempt ' + attempts + '/' + client.max_reconnect_tries + ')';

		client.fatalling = false;
		client.gui_show_dialog(dlgtext);
		if (client.reconnect_tries === 1) {
			client.reconnect_timeout = setTimeout(function() {
				client.gui_show_dialog('Reconnecting to tvChix Chatrooms is currently not possible.\n\nPlease try again later.');
			}, 120000);
		}
	});

	client.socket.on('reconnect_failed', function () {
		// Fired when client could not reconnect after trying reconnectionAttempts times
		client.gui_show_dialog('Cannot connect to tvChix chat');
	});

	client.socket.on('connect_error', function () {
		// Fired when unable to connect
	});

	client.socket.on('reconnect_error', function () {
		// Fired when a single attempt to reconnect has failed
	});

	client.socket.on('disconnect', function (data) {
		client.gui_close_all_privmsg_windows();
		client.gui_clear_modals();
		client.on_disconnect();

		if (!client.fatalling)
		{
			var txt = 'You were disconnected from chat';

			if (client.disconnect_reason !== null)
			{
				if (client.disconnect_reason_iscomplete) {
					txt = client.disconnect_reason;
				}
				else {
					txt += '\n\n' + client.disconnect_reason;
				}
			}
			else {
				txt += '\n\n' + 'Reconnecting...';
			}
			client.gui_show_dialog(txt);
		}

		client.gui_addmsg(client.gui_get_target_room_chatarea(), {type: 'disconnect'});
	});

	client.socket.on('error', function (err) {
		// Fired when an error occurs (unknown usage)
	});

	client.socket.on('fatal', function (data) {
		client.fatalling = true;

		var message = data.message;
		if (data.errorcode) {
			message += '\n\nIf this problem persists please report it in the tvChix Technical Support Forum, quoting error code #' + data.errorcode;
		}

		client.gui_show_dialog(message);
	});

	client.socket.on('clienterror', function(data) {
		client.gui_show_sysmsg(data);

		if (client.join_in_progress) {
			client.join_in_progress = false;
		}

		if (client.previous_room_item) {
			client.gui_set_selected_room(client.previous_room_item);
			client.previous_room_item = null;
		}
	});

	client.socket.on('login', function (data) {
		if (!data.challenge) {
			return;
		}

		client.state.challenge = data.challenge;
		client.begin_login();
	});

	client.socket.on('loggedin', function (data) {
		client.gui_set_window_title(data.username);
		$('#header_username').html(data.username);

		if (data.profilegroup) {
			client.state.set_profilegroup(data.profilegroup);
		}

		if (data.options) {
			client.state.set_options(data.options);
			client.gui_init_options();
			client.gui_apply_options(data.options);
		}

		if (data.privacy) {
			client.state.set_privacy_options(client.default_privacy_status, data.privacy);
			client.gui_init_privacy_options();
		}

		if (data.friends) {
			client.state.set_friend_list(data.friends);
			client.gui_populate_userlist_friends(data.friends);
		}

		if (data.blocks) {
			client.state.set_block_list(data.blocks);
			client.gui_apply_block_list(data.blocks);
		}

		if (data.soundmap && data.sounds) {
			client.state.set_usersounds(data.sounds);
			client.gui_apply_soundmap(data.soundmap, data.sounds);
		}

		if (data.fontdefaults) {
			client.gui_apply_font_defaults(data.fontdefaults);
		}

		if (typeof(data.lastmailid) !== "undefined") {
			client.set_last_mail_id(data.lastmailid);
		}

		if (typeof(data.unread) !== "undefined") {
			client.gui_set_has_unread_mail(data.unread);
		}
	});

	client.set_friend_online_status = function(user, new_state, update_gui) {
		client.state.set_friend_online_status(user, new_state);
		if (update_gui) {
			client.gui_populate_userlist_friends();
		}
	};

	client.set_friend_online = function(user, update_gui) {
		client.set_friend_online_status(user, true, update_gui);
	};

	client.set_friend_offline = function(user, update_gui) {
		client.set_friend_online_status(user, false, update_gui);
	};

	client.socket.on('recent_events', function (data) {
		if (typeof data !== "undefined")
		{
			if (typeof data.connects !== "undefined") {
				_.each(data.connects, function(user) {
					client.gui_update_private_message_status(user.username, false);
				});
			}

			// if you have a priv msg open, we need to process all recent disconnects to indicate theyve disconnected
			if (typeof data.disconnects !== "undefined") {
				_.each(data.disconnects, function(payload) {
					client.on_userquit(payload);
				});
			}

			if (typeof data.recent_status !== "undefined") {
				_.each(data.recent_status, function(user) {
					var privacy_options = client.state.update_room_user_status(user.userid, client.decode_privacy_bits(user.privacy));
					client.gui_update_privacy_indicator(user.username, client.state.profilegroup, privacy_options);
				});
			}
		}

		function get_friends_for_events_list(list) {
			if (!list) {
				return [];
			}

			var usernames = list.map(function(f) {
				return f.username;
			});

			return client.state.friend_list.filter(function(f) {
				return (f.status === 'accepted') && (usernames.indexOf(f.username) >= 0);
			});
		}

		var connected_friends = get_friends_for_events_list(data.connects),
			disconnected_friends = get_friends_for_events_list(data.disconnects);

		_.each(connected_friends, function(friend) {
			if (!client.state.user_in_current_room(friend)) {
				client.gui_addmsg(client.gui_get_target_room_chatarea(), {type: 'friendjoin', username: friend.username});
			}
			client.set_friend_online(friend);
		});

		_.each(disconnected_friends, function(friend) {
			if (!client.state.user_recently_quit_room(friend.username, client.state.room.id)) {
				client.gui_addmsg(client.gui_get_target_room_chatarea(), {type: 'friendquit', username: friend.username});
			}

			client.set_friend_offline(friend);
		});

		if ((connected_friends.length > 0) || (disconnected_friends.length > 0)) {
			client.gui_populate_userlist_friends();
		}

		client.state.clear_recentquits();
	});

	client.socket.on('friendship_updated', function (data) {
		var friend = client.state.change_friend_status(data, 'accepted');
		client.gui_append_friend_to_friendlist(friend);
	});

	client.socket.on('friendship_accepted', function (data) {
		var friend = client.state.change_friend_status(data, 'accepted');
		client.gui_append_friend_to_friendlist(friend);
		client.gui_show_system_notification(friend.username + ' accepted your Friend request');

		// Privacy options don't matter, they're friends now and we don't have privacy data in state.friend_list
		client.gui_update_privacy_indicator(friend.username, client.state.profilegroup, {});

		client.audio.play_sound_friendaccept();
	});

	client.socket.on('friendship_request_received', function (data) {
		// ignore requests from ignored users
		// TODO_CARPII: Needs passing username, and then use state.is_blocked_by_username()
		if (client.state.is_user_blocked_by_username(data.username)) {
			return;
		}

		// m1764 - remove any existing friend record (caters for when a request was sent by this client but declined, then they send one back in same session)
		if (client.state.get_friend_by_username(data.username)) {
			client.state.remove_friend_by_username(data.username);
		}

		// TODO_CARPII: This needs to call into client.add_freind, maybe needs adding more params though
		client.state.add_friend(data.username, data.userid, "awaiting_confirmation");
		client.gui_populate_userlist_friends();
		client.audio.play_sound_friendrequest();
	});

	client.socket.on('friendship_removed', function (data) {
		client.gui_remove_friend_from_friendlist(data.username);
	});

	client.socket.on('blocked', function(data) {
		client.gui_block_user(data.user);
		client.gui_sort_users();
	});

	client.socket.on('usermeta_update', function (data) {
		var meta = data.data;

		client.gui_update_priv_window_meta(meta.username, meta.thumbnail, meta.city, meta.county, meta.country, meta.usertype_desc);
	});

	client.socket.on('mail_update', function(data) {
		if (typeof(data.unread) !== "undefined") {
			client.gui_set_has_unread_mail(data.unread);
		}

		if (typeof(data.id) !== "undefined") {
			if (data.id > client.get_last_mail_id())
			{
				client.set_last_mail_id(data.id);
			}
			client.gui_show_system_notification('You have a new tvChix Message from ' + data.sender + ', click <a class="msglink maillink mailbox" target="_blank" rel="noopener noreferrer" href="//' + client.hosts.web + '/messages/' + data.id + '">here</a> to read it.');
		}
	});

	client.socket.on('roomlist', function (data) {
		client.state.digest('roomlist', data);
		client.gui_show_room_list(data);
	});

	client.socket.on('userjoin', function (data) {
		var type = 'userjoin';
		data.user = client.decode_user_privacy(data.user);
		client.state.digest(type, data);
		client.gui_addmsg(client.gui_get_target_room_chatarea(), {type: type, username: data.user.username, userid: data.user.userid});
		client.gui_add_user(data.user);
		client.audio.play_sound_userjoin();
		client.set_friend_online(data.user, true);

		client.gui_set_room_title(client.state);
		client.gui_sort_users();

		client.gui_update_privacy_indicator(data.user.username, client.state.profilegroup, data.user.privacy);
	});

	client.socket.on('userleave', function (data) {
		var type = 'userleave',
			username = data.username;

		client.gui_addmsg(client.gui_get_target_room_chatarea(), {type: type, username: username});
		client.gui_removeuser(username, false);
		client.state.digest(type, data);

		client.gui_set_room_title(client.state);
	});

	client.socket.on('userquit', function (data) {
		client.on_userquit(data);
	});

	client.socket.on('eject', function (data) {
		if (data.refresh)
		{
			data.reason += client.compile_template(client.templates.static_reloader, { vars: {version: data.version} });
		}

		client.gui_close_all_privmsg_windows();
		client.disconnect_reason_iscomplete = data.is_complete;
		client.disconnect_reason = data.reason;
		client.socket.disconnect();
	});

	client.socket.on('joined', function (data) {
		for (var i = 0; i < data.userlist.length; i++) {
			data.userlist[i] = client.decode_user_privacy(data.userlist[i]);
		}

		client.state.set_room(data.roomid, data.roomname);
		client.state.set_room_users(data.userlist);
		client.gui_joined_room(client.state);
		client.gui_show_chat();

		client.gui_populate_userlist(data.userlist);
		client.gui_sort_users();

		if (data.history) {
			for (var i = 0; i < data.history.length; i++) {
				if (data.history[i].username) {
					if (client.state.is_user_blocked_by_username(data.history[i].username)) {
						continue;
					}
				}
				client.gui_addmsg(client.gui_get_target_room_chatarea(), data.history[i]);
			}
		}

		client.gui_addmsg(client.gui_get_target_room_chatarea(), {type: 'joined', username: data.username, roomname: data.roomname});

		var room_meta = client.state.get_room(data.roomid);
		if (room_meta && room_meta.welcometext) {
			// split welcome text into lines (CRLF), and add seperate welcome entry for each non-blank line
			var lines = room_meta.welcometext.match(/[^\r\n]+/g);
			lines.forEach(function(line) {
				client.gui_addmsg(client.gui_get_target_room_chatarea(), {type: 'welcome', username: data.username, roomname: data.roomname, welcometext: line });
			});
		}

		client.init_emojipicker($('#chatinput'), client.send_input_and_clear.bind(client));
		client.gui_focus_chat_input();
		client.gui_toggle_next_prev_tab_buttons();
	});

	client.socket.on('msg', function (data) {
		if (!data || !data.username) {
			return;
		}

		var message_user = client.state.get_user_by_username(data.username);
		if (message_user) {
			if (message_user.avatarid !== data.avatarid) {
				client.gui_update_avatars(data.username, data.avatarid);
			}
		}

		// 2016/10/16 - Dont allow messages from mods to be blocked
		if (!data.mod) {
			// TODO_CARPII: test
			if (client.state.is_user_blocked_by_username(data.username)) {
				return;
			}
		}

		client.gui_addmsg(client.gui_get_target_room_chatarea(), data);
	});

	client.socket.on('roompeeked', function (data) {
		// check roomid is present
		if (typeof data.roomid === "undefined") {
			return;
		}

		// check roomid matches what was requested earlier
		if (data.roomid !== client.peek_roomid) {
			return;
		}

		// if roomlist is still visible
		if ($("#roomlist").is(":visible")) {

			// replace roomlist contents with roompeeked template
			client.gui_show_roompeeked(data);
		}
	});

	client.socket.on('priv', function (data) {
		if (!data || !data.username) {
			return;
		}

		client.state.cache_user(data.username);

		if (!data.mod) {
			if (client.state.is_user_blocked_by_username(data.username)) {
				return;
			}

			// unless users are friends
			if (!client.state.get_friend_by_username(data.username)) {
				// Check if able to message user due to privacy settings
				if (!client.state.allow_private_message(data.profilegroup, client.state.privacy_options)) {
					// Check if there's an existing window open, if so allow it
					var privchatwindow = client.gui_find_priv_inner_container(data.username);
					if (privchatwindow.length === 0) {
						return;
					}
				}
			}
		}

		client.gui_show_priv(data.username, false, data);
	});

	client.socket.on('sysmsg', function (data) {
		client.gui_show_sysmsg(data);
	});

	client.socket.on('room_counts', function (data) {
		for (var i = 0; i < data.length; i++) {
			var room = data[i],
				$room = $('#rooms span[data-roomid="'+room.id+'"]');

			if ($room.length > 0) {
				$room.text(room.count);
			}
		}
	});

	client.socket.on('redir', function (data) {
		if (data && data.url) {
			client.fatalling = true;
			client.socket.disconnect();
			window.location.replace(data.url);
		}
	});

	// 2017/09/24 - disables Firefox's content editable resizing handles, not sure if did anything and dont want to risk breaking other browsers
	// worked around it by swallowing a mousedown if emojionearea already has focus
	//document.execCommand("enableObjectResizing", false, false);
});

client.gui_update_privacy_indicator = function(username, profilegroup, privacy_options) {
	var indicator_class = client.state.allow_private_message(profilegroup, privacy_options) ? 'available' : 'unavailable';
	var indicator = $("#online_list div.useritem[data-username='" + username + "'] div.privacy-indicator");

	// Always show self as available
	if (username === client.state.username) {
		indicator_class = 'available';
	}

	// Always show friends as available
	if (client.state.get_friend_by_username(username)) {
		indicator_class = 'available';
	}

	indicator.removeClass().addClass("privacy-indicator " + indicator_class);
};

client.gui_apply_block_list = function(blocks) {
	var this_client = this;
	_.each(blocks, function (b) {
		this_client.gui_block_user(b);
	});
};

client.gui_set_has_unread_mail = function(unread) {
	$("#mailbutton .button").toggleClass("unread", (unread > 0));
	this.unread_count = unread;
};

client.gui_options_reset_sound_dropdowns = function() {
	var usersound = $('#usersound'),
		soundevent = $('#soundevent');

	usersound.data('mute', true);

	var item = usersound.jqxDropDownList('getItemByValue', client.audio.get_user_sound_idx(soundevent.jqxDropDownList('getSelectedItem').value));

	if (item) {
		usersound.jqxDropDownList('selectItem', item);
	} else {
		usersound.jqxDropDownList('selectIndex', 0);
	}

	usersound.data('mute', false);
};

client.gui_apply_soundmap = function(soundmap, sounds) {
	client.audio.set_global_mute(client.state.options.global_mute);
	client.audio.setup(soundmap, sounds);

	$('#soundmute').jqxCheckBox();
	$('#soundmute').on('change', function(evt) {
		$('#soundevent').jqxDropDownList({disabled: evt.args.checked});
		$('#usersound').jqxDropDownList({disabled: evt.args.checked});
		$('#sound_option_play').css({visibility: (evt.args.checked ? 'hidden' : 'visible')});
	});


	Widget.DropDownListFromTemplate('#usersound', this.templates.usersound, {vars: {
		sounds: soundmap
	}}, {
		theme: 'custom',
		dropDownWidth: '300px'
	});

	// Need to requery DOM for this after creating it, or change event won't fire
	$('#usersound').on('change', function(event) {
		var soundevent = $('#soundevent'),
			eventItem = soundevent.jqxDropDownList('getSelectedItem'),
			itemValue = parseInt(event.args.item.value, 10);

		soundMapDelta[eventItem.value] = itemValue;
		if (!$(this).data('mute')) {
			$('#sound_option_play').click();
		}
		$('#sound_option_play').toggleClass('disabled', (itemValue === 0));
	});

	Widget.DropDownListFromTemplate('#soundevent', this.templates.soundevent, {vars: {
		events: Object.keys(sounds),
		names: ClientAudio.SOUND_EVENTS
	}}, {
		theme: 'custom',
		dropDownWidth: '300px'
	});

	$('#sound_option_play').click(function() {
		var usersound = $('#usersound'),
			btn = $(this);

		if (btn.hasClass('disabled')) {
			return;
		}

		btn.addClass('disabled');
		var idx = parseInt(usersound.jqxDropDownList('getSelectedItem').value, 10);

		client.audio.preview(idx);

		setTimeout(function() {
			btn.removeClass('disabled');
		}, 1000);
	});

	var onChangeEvent = function(val) {
		var usersound = $('#usersound'),
			item = usersound.jqxDropDownList('getItemByValue', soundMapDelta[val] || sounds[val]);

		if (typeof item !== "undefined") {
			usersound.jqxDropDownList('selectItem', item);
			$('#sound_option_play').toggleClass('disabled', (parseInt(item.value, 10) === 0));
		}
	};

	// Need to requery DOM for this after creating it, or change event won't fire
	$('#soundevent').on('change', function(event) {
		var val = event.args.item.value;
		onChangeEvent(val);
	});

	if (client.state.options.global_mute) {
		$('#soundmute').jqxCheckBox('check');
	} else {
		$('#soundmute').jqxCheckBox('uncheck');
	}

	onChangeEvent('selfjoin');
};

client.set_last_mail_id = function(mailid) {
	this.last_mail_id = mailid;
};

client.get_last_mail_id = function() {
	return this.last_mail_id;
};

setInterval(function() {
	if ($('#roomlist').is(':visible')) {
		client.net.room_counts();
	}
}, 10000);


//window.onbeforeunload = function() {
//	return 'You are about to close client.'
//}
