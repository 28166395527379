'use strict';
var Templates = function() {};
Templates.prototype.popup = [
	'<a class="menulink" id="menulink_<%= vars.id %>"',
		'<% if (vars.href) { %> href="<%= vars.href %>"<% } %>',
		'<% if (vars.newtab) { %> target="_blank" rel="noopener noreferrer" <% } %>',
	'>',
	'<div class="<%= vars.css %>">',
		'<%= vars.label %>',
	'</div>',
	'</a>'
].join(' ');

Templates.prototype.tab = [
	'<li id="<%= vars.tabid %>"><span class="privtab" data-tabname="maintab_priv_<%= vars.username %>">',
		'<%= vars.label %></span>',
	'</li>'
].join(' ');

Templates.prototype.tab_content = [
	'<div id="<%= vars.divid %>" class="tab_content" data-parent="<%= vars.parent_tab_id %>">',
	'</div>'
].join(' ');

Templates.prototype.message = [
		"<div data-msgid='<%= vars.msgid %>' class='message",
			"<% if (vars.className) {%> <%= vars.className %><% } %>",
			"<% if (vars.historic) { %> historic<% } %>",
			"<% if (vars.system) { %> system<% } %>",
			"<% if (vars.kickban) { %> kickban<% } %>",
			"<% if (vars.me) { %> me<% } %>",
			"<% if (vars.targetme) { %> targetme<% } %>",
			"'>",
			"<% if (vars.prefix) { %> <%= vars.prefix %><% } %>",
			"<span class='",
				"<% if (vars.fontid >= 0) { %>font<%= vars.fontid %> <% } %> ",
				"<% if (vars.color > 0) { %>color<%= vars.color %> <% } %> ",
				"<% if (vars.fontbold) { %>fontbold<% } %> ",
				"<% if (vars.fontitalic) { %>fontitalic <% } %>",
				"'>&nbsp;",
				"<%= client.check_shortcuts(vars.msg, vars.username) %>\n",
			"</span>",
			"&nbsp;<br>",
		"</div>"
].join('');

Templates.prototype.userlist_empty = [
	'<div class="presentation">',
		'<div class="gallery<% if (!vars.listmode) { %> selected<% } %>">Gallery</div>',
		'<div class="list<% if (vars.listmode) { %> selected<% } %>">List</div>',
	'</div>'
].join(' ');

Templates.prototype.useritem = [
	"<div class='useritem ",
				"<% if (vars.user.profilecolor) { %>ui_<%= vars.user.profilecolor %><% } %>",
				"<% if (vars.user.online) { %> online <% } %>",
				"<% if (vars.user.pending) { %> pending_request <% } %>",
				"<% if (vars.user.blocked) { %> blocked <% } %>",
				"<% if (vars.user.available) { %> available <% } %>",
			"'", 
			"<% if (vars.user.mod) { %>data-mod='<%= vars.user.mod %>'<% } %> ",
			"data-userid='<%= vars.user.userid %>' ",
			"data-username='<%= vars.user.username %>' ",
			"data-gender='<%= vars.user.gender %>' ",
			"data-usertype='<%= vars.user.usertype %>'",
		">",
		"<div class='inlineavatar av-<%= vars.user.avatarid %>'></div>",
		"<% if (vars.show_indicator) { %>",
			"<div class='privacy-indicator <%= vars.available %>'></div>",
		"<% } %>",
		"<div class='uname_privbtn'></div>",
		"<a class='useritem_uname' href='//<%= vars.web_host %>/profiles/<%= vars.user.username %>'><%= vars.user.username %></a><br>",
		"<% if (vars.show_usermeta) { %>",
			"<div class='usermeta'>",
				"<% if (vars.user.thumb) { %>",
					"<div class='img'><img height='50' width='50' src='<%= vars.user.thumb %>'></div>",
				"<% } else { %> ",
					"<div class='img'></div>",
				"<% } %> ",
				"<div class='metalocation'>",
					"<% if (vars.user.city) { %> <div class='city'><%= vars.user.city %></div><br><% } %>",
					"<% if (vars.user.county) { %> <div class='county'><%= vars.user.county %></div><br><% } %>",
					"<% if (vars.user.country) { %> <div class='country'><%= vars.user.country %></div><br><% } %>",
				"</div>",
			"</div>",
		"<% } %>",
	"</div>"
].join(' ');

Templates.prototype.blocklist_empty_hint = [
	'<div class="userlist_hint">You have not blocked any users</div>'
].join(' ');

Templates.prototype.friendlist_empty_hint = [
	'<div class="userlist_hint">You have not added or accepted any friends yet</div>'
].join(' ');

Templates.prototype.useritem_blocked = [
	"<div class='useritem blocked' data-username='<%= vars.user.username %>' data-userid='<%= vars.user.userid %>'>",
		"<%= vars.user.username %>",
		"<div class='uname_unblockbtn'></div>",
	"</div>"
].join(' ');

Templates.prototype.useraccept = [
	"<div class='friend_controls' data-userid='<%= vars.user.userid%>'>",
		"<div data-userid='<%= vars.user.userid%>' class='friendbutton accept'>Accept</div>",
		"<div data-userid='<%= vars.user.userid%>' class='friendbutton decline'>Decline</button>",
	"</div>"
].join(' ');

Templates.prototype.avatarpicker = [
	"<% _.each(_.range(10), function(v1) { %>",
		"<div class='avatar_row'>",
			"<% _.each(_.range(4), function(v2) { %>",
				"<% var avid = v1*4 + v2 %>",
				"<div class='avatar' data-avatarid='<%= avid %>'>",
					"<div class='av-<%= avid%>'>",
					"</div>",
				"</div>",
			"<% }) %>",
		"</div>",
	"<% }); %>"
].join(' ');

Templates.prototype.colorpicker = [
	"<% _.each(_.range(4), function(v1) { %>",
		"<div class='rowcolor'>",
			"<% _.each(_.range(4), function(v2) { %>",
				"<% var colid = v1*4 + v2 %>",
				"<div class='color color<%= colid %>' data-colorid='<%= colid %>'>",
				"</div>",
			"<% }) %>",
		"</div>",
	"<% }); %>"
].join(' ');

Templates.prototype.roomlist = [
	'<div class="container">',
		'<div class="roomlist">',
			'<div class="opthead">Select a room to join: </div>',
			'<div id="rooms">',
				'<% _.each(vars.rooms, function(room) { %>',
					'<div class="roomitem <% if (room.current) { %>currentroom<% } %>" data-usercount="<%= room.users %>" data-roomid="<%= room.id %>">',
						'<div class="roomusercount">',
							'<span data-roomid="<%= room.id %>"><%= room.users %></span>',
							'<% if (room.userlimit === 0) { %><% } else { %><div class="roomlimit"><% if (room.users >= room.userlimit) { %>FULL<% } else { %>MAX <%= room.userlimit %><% } %></div><% } %>',
						'</div>',
						'<div class="roomdescription">',
							'<%= room.name %>',
							'<div class="descriptiontext"><%= room.description %></div>',
					  '</div>',
					'</div>',
				'<% }); %>',
			'</div>',
		'</div>',
		'<div class="peeklist peeklist-container hidden">',
			'<div id="jqxpeeklistselect"></div>',
			'<div id="peeklist"></div>',
		'</div>',
	'</div>',
	'<div class="roomlist-options">',
		'<div class="roomlist">',
			'<% if (vars.show_cancel_btn) { %>',
				'<button id="roomcancel" type="button" class="v2 button-left">Cancel</button>',
			'<% } else { %>',
				'<button id="roombackbutton" class="v2 button-left">&laquo; Back to tvChix</button>',
			'<% } %>',
			'<button id="roompreview" type="button" class="v2 button-right">Preview &raquo;</button>',
		'</div>',
		'<div class="peeklist hidden">',
			'<button id="roomback" type="button" class="v2 button-left">&laquo; Back</button>',
			'<button id="roompeekjoin" type="button" class="v2 button-right">Join Room</button>',
		'</div>',
	'</div>'
].join(' ');

Templates.prototype.roomlist_empty = [
		'<div class="roompeekuser">',
			'<div class="empty">Room is empty</div>',
		'</div>',
].join(' ');

Templates.prototype.roomlist_peeked = [
  '<% if (vars.friends.length > 0) { %>',
		'<h3>Friends in Room</h3>',
		'<% _.each(vars.friends, function(user) { %>',
			'<div class="roompeekuser">',
				'<div class="thumb"><% if (user.thumb) { %><img src="<%= user.thumb %>"><% } %></div>',
				'<div class="userinfo">',
					'<div class="user ui_<%= user.profilecolor %>" data-username="<% if (user.username) { %><%= user.username %><% } %>"><% if (user.username) { %> <%= user.username %><% } %></div>',
					'<div class="desc"><%= user.usertype_desc %> <% if (user.location.length > 0) { %> from <%= user.location %><% } %></div>',
				'</div>',
			'</div>',
		'<% }); %>',
	'<% } %>',
	'<% if (vars.others.length > 0) { %>',
		'<h3>Users in Room</h3>',
		'<% _.each(vars.others, function(user) { %>',
			'<div class="roompeekuser">',
				'<div class="thumb"><% if (user.thumb) { %><img src="<%= user.thumb %>"><% } %></div>',
				'<div class="userinfo">',
					'<div class="user ui_<%= user.profilecolor %>" data-username="<% if (user.username) { %><%= user.username %><% } %>"><% if (user.username) { %> <%= user.username %><% } %></div>',
					'<div class="desc"><%= user.usertype_desc %> <% if (user.location.length > 0) { %> from <%= user.location %><% } %></div>',
				'</div>',
			'</div>',
		'<% }); %>',
	'<% } %>',
].join(' ');

Templates.prototype.news_skeleton = [
	'Latest Changes<br>',
	'<div id="newscontent" class="container">',
	'</div>',
	'<button id="newsclose" class="v2 btncancel">Close</button>',
].join(' ');

Templates.prototype.news_content = [
	'<div class="version_head">v2.62</div>',
	'<div class="version_body">Removed Looking To Meet (Online Only) room</div>',
	'<div class="version_head">v2.59</div>',
	'<div class="version_body">Allow for a wider userlist when device supports it</div>',
	'<div class="version_body">Fixed bug preventing autocomplete of emojis (type : to bring up picker)</div>',
	'<div class="version_body">Minor cosmetic and performance tweaks</div>',
	'<div class="version_head">v2.51</div>',
	'<div class="version_body">Minor maintenance release</div>',
	'<div class="version_head">v2.28</div>',
	'<div class="version_body">Minor maintenance release</div>',
	'<div class="version_head">v2.26</div>',
	'<div class="version_body">Added room limits. Friends of tvChix can always join rooms, even when they are full</div>',
	'<div class="version_head">v2.22</div>',
	'<div class="version_body">Minor bug fix to chat layout</div>',
	'<div class="version_head">v2.21</div>',
	'<div class="version_body">Announce to room when a user is kicked or banned by a moderator</div>',
	'<div class="version_head">v2.20</div>',
	'<div class="version_body">Address stability issues which were occurring over past week. Please do report recurring issues in the Chat Support Forum, thankyou</div>',
	'<div class="version_head">v2.19</div>',
	'<div class="version_body">Minor upgrades to websocket library for improved performance</div>',
	'<div class="version_head">v2.18</div>',
	'<div class="version_body">Minor fixes to Room Preview feature</div>',
	'<div class="version_body">Automatically lowercase messages which are ALL IN CAPS</div>',
	'<div class="version_body">Fix presentation issue affecting a private message which has been docked into a tab</div>',
	'<div class="version_head">v2.17</div>',
	'<div class="version_body">The Room Preview feature now allows you to view a users profile</div>',
	'<div class="version_body">Allow messaging of your chat friends even if they are in a different room</div>',
	'<div class="version_body">Numerous under-the-hood performance enhancements</div>',
	'<div class="version_head">v2.16</div>',
	'<div class="version_body">Fix intermittent bug affecting priv msg emojis</div>',
	'<div class="version_head">v2.15</div>',
	'<div class="version_body">Fix bug which occasionally caused keyboard focus to jump to another window</div>',
	'<div class="version_body">Fix bug relating to private message preferences</div>',
	'<div class="version_body">Internal performance improvements</div>',
	'<div class="version_head">v2.14</div>',
	'<div class="version_body">Sort Preview userlist alphabetically to be consistent with room userlist</div>',
	'<div class="version_head">v2.13</div>',
	'<div class="version_body">Fix regression with settings window appearing only briefly</div>',
	'<div class="version_head">v2.12</div>',
	'<div class="version_body">Added Preview button to room list, so you can see who is chatting before joining the room</div>',
	'<div class="version_body">Allow users to reconnect more easily, if they timed out due to network connectivity issues</div>',
	'<div class="version_head">v2.11</div>',
	'<div class="version_body">Add support for latest Emoji standard (various new smileys, flags and icons)</div>',
	'<div class="version_body">Fix and issue with small-screen Android devices displaying keyboard incorrectly</div>',
	'<div class="version_head">v2.10</div>',
	'<div class="version_body">Fix a bug which prevented private messaging of Friends when they are in different room</div>',
	'<div class="version_head">v2.09</div>',
	'<div class="version_body">Re-organisation of available chatrooms..</div>',
	'<div class="version_body">Added new BDSM and Fantasy/Roleplay Chat rooms.</div>',
	'<div class="version_body">Discontinued Girls and Admirers, room due to relatively low usage</div>',
	'<div class="version_body">Added welcome message to help explain each rooms purpose</div>',
	'<div class="version_body">These changes are experimental. Feedback is always welcome</div>',
	'<div class="version_head">v2.05</div>',
	'<div class="version_body">Privacy toolbar button added, allowing you to block private messages according to profile type</div>',
	'<div class="version_head">v2.04</div>',
	'<div class="version_body">Userlist now defaults to Gallery view, can still be toggled using the List/Gallery links</div>',
	'<div class="version_body">Fix bug which was causing some users to lose connection when typing the blue number emojis</div>',
	'<div class="version_body">Fix bug causing the new Global Mute option not to be saved properly, only affected some users.</div>',
	'<div class="version_head">v2.03</div>',
	'<div class="version_body">Mute button removed. Added new "Mute All Sounds" option in Options &raquo; Sounds</div>',
	'<div class="version_head">v2.02</div>',
	'<div class="version_body">Workaround for bug in EmojiOne, which was causing issues with Internet Explorer 11.</div>',
	'<div class="version_head">v2.01</div>',
	'<div class="version_body">Upgraded Emojis to new 3D-style appearance.</div>',
	'<div class="version_body">Now supports the entire range of Emojis (over 2600 of them!)</div>',
	'<div class="version_body">Can now fully customise chat sounds from Options &raquo; Audio</div>',
	'<div class="version_head">v2.00</div>',
	'<div class="version_body">Minor fixes for non-Firefox browsers</div>',
	'<div class="version_head">v1.90</div>',
	'<div class="version_body">Potential fix for multiple join/leave messages, when user is on slow connection and repeatedly clicks roomlist</div>',
	'<div class="version_head">v1.89</div>',
	'<div class="version_body">Attempt to improve private message positioning, so they are less likely to underlap</div>',
	'<div class="version_head">v1.87</div>',
	'<div class="version_body">Minor stability update</div>',
	'<div class="version_head">v1.86</div>',
	'<div class="version_body">Priv messages to/from moderators now clearly show their mod status</div>',
	'<div class="version_body">Added new option to show timestamps alongside messages</div>',
	'<div class="version_head">v1.85</div>',
	'<div class="version_body">Room messages directed at you will now be highlighted in yellow (can be disabled in Options &raquo; Messages)</div>',
	'<div class="version_body">Added new "links" toolbar button</div>',
	'<div class="version_body">Improvements to moderator features</div>',
	'<div class="version_head">v1.82</div>',
	'<div class="version_body">Fixed badly positioned Emoji/Color picker (for Windows 10 and possibly other platforms)</div>',
	'<div class="version_body">Prevent room tab from disappearing on very small screen devices</div>',
	'<div class="version_head">v1.80</div>',
	'<div class="version_body">Priv message windows now respect font size setting in Options</div>',
	'<div class="version_body">Experimental support for copying selected chat log by right click &raquo; Copy (needs a bit more work for Windows users)</div>',
	'<div class="version_body">More changes to support upcoming https</div>',
	'<div class="version_head">v1.79</div>',
	'<div class="version_body">Improve mailbox popup behavior</div>',
	'<div class="version_body">Fix bug relating to certain types of web urls being pasted</div>',
	'<div class="version_body">Preliminary changes to support https</div>',
	'<div class="version_head">v1.75</div>',
	'<div class="version_body">Theming of Font Picker</div>',
	'<div class="version_head">v1.74</div>',
	'<div class="version_body">Fix recent bug which interfered with room/message tabs on small screen devices</div>',
	'<div class="version_body">Minor stability improvements for high latency connections</div>',
	'<div class="version_head">v1.73</div>',
	'<div class="version_body">Added confirmation dialog when blocking someone from the popup menu</div>',
	'<div class="version_body">Fixed a rare bug which could result in losing chatroom connection, after trying to block someone</div>',
	'<div class="version_body">Viewing your tvChix Mailbox now opens in a new browser tab</div>',
	'<div class="version_head">v1.72</div>',
	'<div class="version_body">Reverted latest scroll changes, pending further testing. This issue is proving rather difficult to fix</div>',
	'<div class="version_head">v1.71</div>',
	'<div class="version_body">Attempt to fix issues with chatroom scrolling. If you are still having a problem, please report it in our Tech Support Forum, Thankyou.</div>',
	'<div class="version_head">v1.69</div>',
	'<div class="version_body">Added mail button, will light up when you have unread tvChix Messages</div>',
	'<div class="version_body">Added news button to show latest changes to tvChix Chat</div>',
	'<div class="version_head">v1.68</div>',
	'<div class="version_body">System message now appears in the chatroom when you get a new tvChix Message</div>',
	'<div class="version_head">v1.67</div>',
	'<div class="version_body">Fixes to the way chat server handles some web links</div>',
	'<div class="version_body">Added new option to show a confirmation dialog when clicking a web link, to avoid accidentally navigating away from the chatrooms</div>',
	'<div class="version_head">v1.66</div>',
	'<div class="version_body">Your chosen room font, color and bold/italic settings are automatically remembered</div>',
	'<div class="version_head">v1.65</div>',
	'<div class="version_body">General stability improvements for users using Internet Explorer 10 and 11</div>',
	'<div class="version_head">v1.64</div>',
	'<div class="version_body">General stability fixes for Internet Explorer</div>',
	'<div class="version_head">v1.63</div>',
	'<div class="version_body">Clicking a username in chat room now shows same popup menu as the userlist does</div>',
	'<div class="version_head">v1.62</div>',
	'<div class="version_body">Changes to the way userlist and friends list are sorted</div>',
	'<div class="version_head">v1.61</div>',
	'<div class="version_body">Fixes to Gallery Mode for Internet Explorer 11</div>',
	'<div class="version_head">v1.60</div>',
	'<div class="version_body">Internal stability improvements</div>',
	'<div class="version_head">v1.59</div>',
	'<div class="version_body">Various bug fixes for users on mobile devices</div>',
	'<div class="version_head">v1.58</div>',
	'<div class="version_body">Automatically hyperlink web links pasted into chat or Private Message</div>',
	'<div class="version_head">v1.58</div>',
	'<div class="version_body">Bug fixes to admin broadcast feature</div>',
	'<div class="version_head">v1.57</div>',
	'<div class="version_body">Ensure chatroom automatically scrolls on new message</div>',
	'<div class="version_head">v1.56</div>',
	'<div class="version_body">Introduced Gallery Mode to see peoples profile thumbnails and location in userlist</div>',
].join(' ');

Templates.prototype.maillist = [
	"<% if (vars.unread_count > 0) { %>",
		"You have <%= vars.unread_count %> unread tvChix messages<br><br>",
		"<a class='mailbox' target='_blank' rel='noopener noreferrer' href='//<%= vars.web_host %>/mailbox.php'>View your tvChix inbox</a><br><br>",
	'<% } else { %>',
		"You have no unread tvChix messages<br><br>",
		"<a class='mailbox' target='_blank' rel='noopener noreferrer' href='//<%= vars.web_host %>/mailbox.php'>View your tvChix inbox</a><br><br>",
	'<% } %>',
].join(' ');

Templates.prototype.weblinks = [
	"<ul class='weblinks'>",
		"<li><a class='weblink' target='_blank' href='//<%= vars.web_host %>/chatabuse'>Report Chatroom Abuse</a></li>",
		"<li><a class='weblink' target='_blank' href='//<%= vars.web_host %>/forum/forum.php?fid=20'>Report a Technical Issue</a></li>",
		"<li><a class='weblink' target='_blank' href='//<%= vars.web_host %>/donate.php'>Support tvChix</a></li>",
	"</ul>",
].join(' ');

Templates.prototype.privacy = [
// TODO_CARPII: Add back in with availability dropdown
//	"Status:",
//	"<select id='privacystatus' name='privacystatus'>",
//		"<option <% if (vars.privacystatus == 'available') { %>selected='selected'<% } %> value='available'>Available</option>",
//		"<option <% if (vars.privacystatus == 'busy') { %>selected='selected'<% } %> value='busy'>Busy</option>",
//		"<option <% if (vars.privacystatus == 'away') { %>selected='selected'<% } %> value='away'>Away</option>",
//		"<option <% if (vars.privacystatus == 'dnd') { %>selected='selected'<% } %> value='dnd'>Do Not Disturb</option>",
//	"</select>",
//	"<br><br>",
	"Accept messages from:",
	"<ul id='privacy_settings' class='privacy'>",
		"<li><label class='tvcd'><input id='privacy_tvcd' type='checkbox' value='1' <% if (vars.tvcd) { %>checked='checked'<% } %> data-privacy='tvcd'>TV/CD</label></li>",
		"<li><label class='ts'><input id='privacy_ts' type='checkbox' value='1' <% if (vars.ts) { %>checked='checked'<% } %> data-privacy='ts'>TS/TG</label></li>",
		"<li><label class='maleadm'><input id='privacy_maleadm' type='checkbox' value='1' <% if (vars.maleadm) { %>checked='checked'<% } %> data-privacy='maleadm'>Male Admirer</label></li>",
		"<li><label class='femaleadm'><input id='privacy_femaleadm' type='checkbox' value='1' <% if (vars.femaleadm) { %>checked='checked'<% } %> data-privacy='femaleadm'>Female Admirer/S.O</label></li>",
	"</ul>",
	"<div class='c'><button id='btnprivacysave' class='v2' type='button'>Save</button></div>",
	"<div class='c subtext'>Anyone on your Friends list<br> will always be able to message you.</div>"
].join(' ');

Templates.prototype.roomselect = [
	'<% _.each(vars.rooms, function(room) { %>',
		'<option value="<%= room.id %>" data-roomid="<%= room.id %>" <% if (room.id == vars.selected_roomid) { %>selected="selected"<% } %>><%= room.name %></option>',
	'<% }); %>',
].join(' ');

Templates.prototype.usersound = [
	'<option value="0" data-soundid="0">No Sound</option>',
	'<% _.each(vars.sounds, function(snd, idx) { %>',
		'<option value="<%= idx %>" data-soundid="<%= idx %>"><%= snd.name %></option>',
	'<% }); %>',
].join(' ');

Templates.prototype.soundevent = [
	'<% _.each(vars.events, function(evt, idx) { %>',
		'<option value="<%= evt %>" data-soundid="<%= evt %>"><%= vars.names[evt] %></option>',
	'<% }); %>',
].join(' ');

Templates.prototype.sysmsg = [
	'<div class="dialog sysmsg<% if (vars.class) { %> <%= vars.class %><% } %>">',
		'<div class="dlg-body">',
			'<h3><% if (vars.title) { %><%= vars.title %><% } else { %>System message<% } %></h3>',
			'<p><%= vars.message %></p>',
			'<div class="button sclose"><% if (vars.button_text) { %><%= vars.button_text %><% } else { %>Close<% } %></div>',
		'</div>',
	'</div>'
].join(' ');

Templates.prototype.sysmsg_navaway = [
	'<div>This will navigate away from chat, click the link to continue:</div><br>',
	'<a class="link" href="<%= vars.link %>"><%= vars.linkText %></a><br><br>',
	'<div class="footnote"><% if (!vars.mobile_mode) { %>Hold CTRL or CMD key and click, to open link in new browser tab<% } %></div>',
].join(' ');

Templates.prototype.confirmbox = [
	'<div class="dialog sysmsg confirmbox">',
		'<div class="dlg-body">',
			'<h3><%= vars.title %></h3>',
			'<p><%= vars.message %></p>',
			'<div class="button cbok"><%= vars.confirm %></div>',
			'<div class="button cbclose"><%= vars.cancel %></div>',
		'</div>',
	'</div>'
].join(' ');

Templates.prototype.privdialog = [
	'<div class="dialog privdialog" style="display: none"></div>'
].join(' ');

Templates.prototype.privcontainer = [
	'<div class="privcontainer">',
		'<div class="header"><%= vars.username %></div>',
		'<div class="buttons">',
			'<div class="dlgbutton pblock">',
				'<svg viewBox="0 0 391.935 391.935" style="enable-background:new 0 0 391.935 391.935;"><g><circle cx="108.75" cy="76.878" r="60"/><path d="M371.726,234.221V205.21c0-32.539-26.473-59.011-59.012-59.011s-59.012,26.472-59.012,59.011v29.011h-20.209v140.836 h158.441V234.221H371.726z M341.726,234.221h-58.023V205.21c0-15.997,13.015-29.011,29.012-29.011s29.012,13.014,29.012,29.011 V234.221z"/><path d="M108.75,166.878C48.689,166.878,0,215.567,0,275.628h203.494v-53.366C184.838,189.208,149.412,166.878,108.75,166.878z"/></g></svg>',
			'</div>',
			'<div class="dlgbutton ptabify">',
				'<svg class="theme-icon icon-privarrowup" xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="1 3 22 18"><path class="bg" fill="none" d="M3 5h18v14H3V5z"/><path class="inner" fill="#010101" d="M11.4 9.8c.3-.4.9-.4 1.2 0l3.3 4.4c.247.33.012.8-.4.8H8.5c-.41 0-.646-.47-.398-.8L11.4 9.8z"/><path class="outer" fill="#010101" d="M22 21H2c-.552 0-1-.447-1-1V4c0-.552.448-1 1-1h20c.553 0 1 .448 1 1v16c0 .553-.447 1-1 1zM3 19h18V5H3v14z"/></svg>',
			'</div>',
			'<div class="dlgbutton pminimise">',
				'<svg class="theme-icon icon-privarrowdown" xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="1 3 22 18"><path class="bg" fill="none" d="M3 5h18v14H3V5z"/><path class="inner" fill="#010101" d="M8.104 10.3c-.248-.33-.012-.8.398-.8h6.996c.413 0 .648.47.4.8L12.6 14.7c-.3.4-.9.4-1.2 0l-3.296-4.4z"/><path class="outer" fill="#010101" d="M22 21H2c-.552 0-1-.447-1-1V4c0-.552.448-1 1-1h20c.553 0 1 .448 1 1v16c0 .553-.447 1-1 1zM3 19h18V5H3v14z"/></svg>',
			'</div>',
			'<div class="dlgbutton pclose">',
				'<svg class="theme-icon icon-privclose" xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="-4 0 22 18"> <path class="bg" fill="none" d="M-2 2h18v14H-2V2z"/> <path class="outer" fill="#010101" d="M17 18H-3c-.552 0-1-.447-1-1V1c0-.552.448-1 1-1h20c.553 0 1 .448 1 1v16c0 .553-.447 1-1 1zm-19-2h18V2H-2v14z"/> <path class="inner" fill="#010101" d="M8.414 8.938l2.475-2.475c.193-.195.193-.512 0-.707l-.708-.708c-.195-.195-.513-.195-.707 0L7 7.522 4.525 5.048c-.195-.195-.51-.195-.707 0l-.708.708c-.195.195-.195.512 0 .707l2.475 2.475-2.474 2.475c-.193.194-.193.512 0 .707l.71.707c.195.194.51.194.707 0L7 10.352l2.475 2.476c.195.194.512.194.707 0l.707-.707c.193-.194.193-.512 0-.706L8.413 8.938z"/></svg>',
			'</div>',
		'</div>',
	'</div>'
].join(' ');

Templates.prototype.privinnercontainer = [
	'<div class="privinnercontainer" id="privmsg_<%= vars.username %>" data-username="<%= vars.username %>">',
		'<div class="chatarea-container priv">',
			'<div class="chatarea priv selectable"></div>',
		'</div>',
		'<div class="privmodnotice">Moderator</div>',
		'<div class="privbanner">',
			'<div class="close"><svg class="theme-icon icon-closebox" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path class="bg" fill="none" d="M2 2h14v14H2V2z"/><path class="outer" fill="#010101" d="M17 18H1c-.552 0-1-.447-1-1V1c0-.552.448-1 1-1h16c.553 0 1 .448 1 1v16c0 .553-.447 1-1 1zM2 16h14V2H2v14z"/><path class="inner" fill="#010101" d="M10.414 8.938l2.475-2.475c.194-.195.194-.512 0-.707l-.708-.708c-.195-.195-.512-.195-.707 0L9 7.522 6.525 5.048c-.195-.195-.51-.195-.707 0l-.708.708c-.195.195-.195.512 0 .707l2.475 2.475-2.474 2.475c-.194.194-.194.512 0 .707l.71.707c.194.195.51.195.706 0L9 10.352l2.475 2.475c.195.195.512.195.707 0l.707-.707c.194-.195.194-.512 0-.707l-2.476-2.475z"/></svg></div>',
			'<div class="unminimise <% if (!vars.unminimise) { %>hidden<% } %>"><svg class="theme-icon icon-unminimise" xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18"><path class="outer" fill="#010101" d="M21 18H1c-.552 0-1-.447-1-1v-6c0-.553.448-1 1-1s1 .447 1 1v5h18V2h-9c-.552 0-1-.448-1-1s.448-1 1-1h10c.553 0 1 .448 1 1v16c0 .553-.447 1-1 1z"/><path class="arrow" fill="#010101" d="M15 9h-4.586L4.207 2.793 7 0H0v7l2.793-2.793 6.5 6.5.023.023c.195.195.45.293.707.293.04 0 .08-.02.12-.023H15c.553 0 1-.447 1-1 0-.552-.447-1-1-1z"/></svg></div>',
			'<div class="block"><svg class="theme-icon icon-blockuser" xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 18 18"><rect class="bg" fill="#fff" x="1.24" y="1.09" width="19.52" height="15.82"/><path class="inner"  d="M7.92,6.42a3.07,3.07,0,0,1,5.59-1.77L9.23,8.93A3.07,3.07,0,0,1,7.92,6.42Zm4.26,3.24L7.34,14.5h8.54s0-.07,0-.11A4.88,4.88,0,0,0,12.18,9.66ZM5.4,15.3,17.3,3.4a.5.5,0,0,0-.71-.71L4.7,14.6a.5.5,0,1,0,.71.71Z" transform="translate(0 0)"/><path class="outer" d="M21,18H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H21a1,1,0,0,1,1,1V17A1,1,0,0,1,21,18ZM2,16H20V2H2Z" transform="translate(0 0)"/></svg></div>',
			'<div class="thumbnail"></div>',
			'<div class="username"><%= vars.username %></div>',
			'<div class="profiletype"></div>',
			'<div class="location"></div>',
		'</div>',
		'<div class="chatinputcontainer priv">',
			'<div class="chatinput"><input class="chatinput priv" type="text" placeholder="Type here to chat"></div>',
			'<div class="chatsend priv"><div class="button"><span>Send</span></div></div>',
		'</div>',
	'</div>'
].join(' ');

Templates.prototype.get_keyboard_markup = function(type) {
	var
		enterKey = '<svg width="15px" height="15px" viewBox="731 736 21 24"><polygon stroke-width="0" fill="#8593A0" fill-rule="evenodd" points="742.878305 736.741808 751.028426 736.746799 751.028427 752.747803 740.141835 752.747803 740.146997 758.454102 732 752.359168 740.152635 746.219562 740.141835 750.347902 749.209961 750.347901 749.209961 738.990479 742.878304 738.990479"></polygon></svg>',

		us = [["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P", "←"],
			["A", "S", "D", "F", "G", "H", "J", "K", "L", "↩"],
			["⇧", "Z", "X", "C", "V", "B", "N", "M", ","],
			["123", "space"]],
		numbers = [["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "←"],
			["-", "/", ":", ";", "(", ")", "€", "&", "@", "\"", "↩"],
			["#+=", ".", ",", "?", "!", "&#39;"],
			["ABC", "space"]],
		special = [["[", "]", "{", "}", "#", "%", "^", "*", "+", "=", "←"],
			["_", "\\", "|", "~", "<", ">", "$", "£", "¥", "↩"],
			["123", ".", ",", "?", "!", "&#39;"],
			["ABC", "space"]];

	var template = [
		"<div class='keyboard-container'>",
			"<%_.each(vars.keys, function(keyrow){ %>",
				"<div class='keyrow'>",
					"<% _.each(keyrow, function(key) { %>",
						"<% var tooltip = new RegExp(/[a-zA-Z0-9,.]/).test(key) && key.length == 1 %>",
						"<% var symbol = (key !== '↩') ? key : vars.enterKey %>",
						"<div data-key='<%= key%>' class='key<%= (tooltip) ? ' tooltip' : '' %>'><%= symbol %></div>",
					"<% }); %>",
				"</div>",
			"<% }); %>",
		"</div>"
	].join(' ');

	var parseType = function(type) {
		if (!type) {
			return us;
		}
		if (type === 'numbers') {
			return numbers;
		}
		if (type === 'special') {
			return special;
		}
	};

	var compiled = _.template(template);
	return compiled({ vars: {keys: parseType(type), enterKey: enterKey}} );
};

Templates.prototype.static_reloader = [
	'<p style="text-align: center">',
		'<button class="v2" data-version="<%= vars.version %>" id="btn_reload" type="button">Reload</button>',
	'</p>'
].join(' ');
