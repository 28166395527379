(function() {
	'use strict';

	var AUDIO_THROTTLE_MS = 700;
	var SOUND_EVENTS = {
		selfjoin: 'You join a room',
		keyclickenter: 'You send a message',
		userleave: 'Someone leaves the room',
		userjoin: 'Someone enters the room',
		roommsg: 'Someone sends a room message',
		privmsg: 'Someone sends a private message',
		userquit: 'Someone quits chat',
		keyclick: 'Key click (mobile only)',
		friendrequest: 'Friend request received',
		friendaccept: 'Friend request accepted',
		roommention: 'Room mention',
	};

	function AudioPlayer(params) {
		var num_instances = params.instances || 1;

		this.elements = [];
		this.player_index = 0;
		this.last_played = 0;
		this.playback_throttle_ms = params.throttle || AUDIO_THROTTLE_MS;

		// TODO: Add support for multiple sources (ogg?)
		// All browsers except Opera Mini 5.0-8.0 support MP3:
		// http://caniuse.com/#feat=mp3
		// Example of support check
		// if (!element.canPlayType('audio/mpeg')) {
		//		element.src = 'audiofile.ogg');
		// }

		for (var i = 0; i < num_instances; i++) {
			var element = new Audio();
			element.src = 'audio/' + params.src;
			this.elements.push(element);
		}

		this.set_volume(1.0);
	}

	AudioPlayer.prototype.set_attr = function(name, value) {
		for (var i = 0; i < this.elements.length; i++) {
			this.elements[i][name] = value;
		}
	};

	AudioPlayer.prototype.set_volume = function(volume) {
		this.set_attr('volume', volume);
	};

	AudioPlayer.prototype.play = function() {
		var now = new Date().getTime();

		if (now - this.last_played < this.playback_throttle_ms) {
			return;
		}

		if (this.player_index >= this.elements.length) {
			this.player_index = 0;
		}

		this.last_played = now;
		this.elements[this.player_index++].play();
	};

	function ClientAudio(client) {
		this.client = client;
		this.global_mute = false;
		this.map = {};
	}

	ClientAudio.prototype.get_user_sounds = function() {
		return this.usersounds;
	};

	ClientAudio.prototype.set_user_sound = function(event_id, sound_idx) {
		var sound = this.soundmap[sound_idx];

		this.usersounds[event_id] = sound_idx;

		if (!sound) {
			return;
		}

		if (this.map[event_id]) {
			delete this.map[event_id];
		}

		this.map[event_id] = new AudioPlayer({
			id: event_id,
			src: sound.filename,
			throttle: 100,
			instances: 3
		});
	};

	ClientAudio.prototype.get_user_sound_idx = function(event_id) {
		return this.usersounds[event_id];
	};

	ClientAudio.prototype.setup = function(soundmap, usersounds) {
		this.soundmap = soundmap;
		this.usersounds = usersounds;

		for (var k in this.usersounds) {
			if (this.usersounds.hasOwnProperty(k)) {
				this.set_user_sound(k, this.usersounds[k]);
			}
		}
	};

	ClientAudio.prototype.set_global_mute = function(mute) {
		this.global_mute = mute;
	};

	ClientAudio.prototype.preview = function(idx) {
		if (this.soundmap[idx]) {
			var player = new AudioPlayer({
				id: 'options-sound-preview',
				src: this.soundmap[idx].filename,
				throttle: 100,
				instances: 1
			});

			player.play();
		}
	};

	ClientAudio.prototype.play = function(name) {
		if (this.global_mute) {
			return;
		}

		if (this.map[name]) {
			this.map[name].play();
		}
	};

	ClientAudio.prototype.play_sound_selfjoin = function() {
		this.play('selfjoin');
	};

	ClientAudio.prototype.play_sound_room_msg = function() {
		this.play('roommsg');
	};

	ClientAudio.prototype.play_sound_priv_msg = function() {
		this.play('privmsg');
	};

	ClientAudio.prototype.play_sound_keyclick = function() {
		this.play('keyclick');
	};

	ClientAudio.prototype.play_sound_keyclick_send = function() {
		this.play('keyclickenter');
	};

	ClientAudio.prototype.play_sound_userleave = function() {
		this.play('userleave');
	};

	ClientAudio.prototype.play_sound_userjoin = function() {
		this.play('userjoin');
	};

	ClientAudio.prototype.play_sound_userquit = function() {
		this.play('userquit');
	};

	ClientAudio.prototype.play_sound_friendaccept = function() {
		this.play('friendaccept');
	};

	ClientAudio.prototype.play_sound_friendrequest = function() {
		this.play('friendrequest');
	};

	ClientAudio.prototype.play_sound_roommention = function() {
		this.play('roommention');
	};

	ClientAudio.SOUND_EVENTS = SOUND_EVENTS;

	window.ClientAudio = ClientAudio;
})();
