'use strict';
/* global _: false */
(function(root, factory) {
	if (typeof exports === 'object') {
		root.State = require('./state');
		root._ = require('underscore');
		module.exports = factory();
	} else {
		root.State = factory();
	}
}((typeof window !== 'undefined') ? window : global, function() {
	// TODO_CARPII: Add a digest mapping for joined packet to call => set_room_users (uses joined.userlist)
	var digest_fns_map = {
		'roomlist': 'set_room_list',
		'userjoin': 'add_room_user',
		'userleave': 'remove_room_user',
		'userquit': 'userquit'
	};

	function isDefined(value) {
		return !_.isUndefined(value);
	}

	function State() {
		this.username = null;
		this.token = null;
		this.challenge = null;
		this.room = {
			id: 0,
			name: null,
			users: []
		};
		this.user_cache = [];
		this.room_list = [];
		this.block_list = [];
		this.friend_list = [];
		this.message_id = 0;
		this.options = [];
		this.usersounds = {};
		this.privacy_options = null;
		this.privacy_status = null;
		this.recent_friendquits = [];
		this.profilegroup = null;

		this.digest = function(type, data) {
			var digestFn = digest_fns_map[type];
			if (digestFn && _.isFunction(this[digestFn])) {
				this[digestFn](data);
			}
		};

		this.set_block_list = function(data) {
			this.block_list = data;
		};

		this.is_user_blocked = function(userid) {
			var blocked = isDefined(_.findWhere(this.block_list, { userid: userid }));
			return blocked;
		};

		this.is_user_blocked_by_username = function(username) {
			var blocked = isDefined(_.findWhere(this.block_list, { username: username }));
			return blocked;
		};

		this.is_friend = function(username) {
			var friend = this.get_friend_by_username(username);

			return ((friend !== false) && (friend.status === "accepted"));
		};

		this.current_user_can_contact_user = function(username) {
			var recipient = this.get_user_by_username(username);

			if (typeof recipient === "undefined") {
				return false;
			}

			var friend = this.get_friend_by_username(username);
			if ((friend !== false) && (friend.status === "accepted")) {
				return true;
			}

			return this.allow_private_message(this.profilegroup, recipient.privacy);
		};

		this.allow_private_message = function(profilegroup, privacy_options) {
			if ((!privacy_options.tvcd) && (profilegroup === 1)) {
				return false;
			}

			if ((!privacy_options.ts) && (profilegroup === 4)) {
				return false;
			}

			if ((!privacy_options.maleadm) && (profilegroup === 2)) {
				return false;
			}

			if ((!privacy_options.femaleadm) && (profilegroup === 3)) {
				return false;
			}

			return true;
		};

		this.remove_block = function(username, userid) {
			this.block_list = _.reject(this.block_list, function (block) { return block.userid === userid; });
		};

		this.add_block = function(user) {
			this.block_list.push(user);
		};

		this.set_friend_list = function(data) {
			this.friend_list = data;
		};

		this.add_friend = function(username, userid, status) {
			var user = this.get_user_by_username(username);
			if (user) {
				var friend = _.clone(user);
				friend.status = status;
				this.friend_list.push(friend);
			}
		};

		this.remove_friend = function(userid) {
			this.friend_list = _.reject(this.friend_list, function (friend) { return friend.userid === parseFloat(userid); });
		};

		this.remove_friend_by_username = function(username) {
			this.friend_list = _.reject(this.friend_list, function (friend) { return friend.username === username; });
		};

		this.update_user_avatar_id = function(username, avatarid) {
			var i;

			for (i = 0; i < this.friend_list.length; i++) {
				if (this.friend_list[i].username === username) {
					this.friend_list[i].avatarid = avatarid;
					return;
				}
			}

			for (i = 0; i < this.block_list.length; i++) {
				if (this.block_list[i].username === username) {
					this.block_list[i].avatarid = avatarid;
					return;
				}
			}
		};

		this.set_friend_online_status = function(friend, new_status) {
			for (var i = 0; i < this.friend_list.length; i++) {
				if (this.friend_list[i].username === friend.username) {
					this.friend_list[i].online = new_status;
					break;
				}
			}
		};

		this.change_friend_status = function(friend, status) {
			friend.status = status;
			this.remove_friend(friend.userid);
			this.friend_list.push(friend);
			return friend;
		};

		this.get_friend = function(userid) {
			var result = (_.findWhere(this.friend_list, { userid: userid }));
			if (typeof(result) === "undefined") {
				return false;
			}
			return result;
		};

		this.get_friend_by_username = function(username) {
			var result = (_.findWhere(this.friend_list, { username: username }));
			if (typeof(result) === "undefined") {
				return false;
			}
			return result;
		};

		this.get_blocked_by_username = function(username) {
			return (_.findWhere(this.block_list, { username: username } ));
		};

		this.inc_message_id = function() {
			this.message_id++;
		};

		this.set_room_users = function(data) {
			this.room.users = data;
		};

		this.update_room_user_status = function(userid, privacy) {
			var user = _.findWhere(this.room.users, {userid: userid});
			user.privacy = privacy;

			return user.privacy;
		};

		this.user_in_current_room = function(user) {
			var match = _.findWhere(this.room.users, {username: user.username});
			return match;
		};

		this.is_username_in_current_room = function(username) {
			var match = _.findWhere(this.room.users, {username: username});
			return (typeof match !== "undefined");
		};

		this.user_recently_quit_room = function(username, roomid) {
			for (var i = 0; i < this.recent_friendquits.length; i++) {
				var fq = this.recent_friendquits[i];

				if ((fq.username === username) && (fq.roomid === roomid)) {
					return true;
				}
			}

			return false;
		};

		this.clear_recentquits = function() {
			this.recent_friendquits = [];
		};

		this.userquit = function(data) {
			var friend = this.get_friend_by_username(data.username);

			if (friend) {
				this.recent_friendquits = _.filter(this.recent_friendquits, function(f) {
					return f.username !== friend.username;
				});

				this.recent_friendquits.push({
					username: friend.username,
					roomid: this.room.id
				});
			}

			this.remove_room_user(data);
		};

		this.remove_room_user = function(data) {
			var username = data.username;
			this.room.users = _.reject(this.room.users, function (user) { return user.username === username; });
		};

		this.cache_user = function(username) {
			var user = this.get_user(username);
			if (!this.get_user(username, true)) {
				this.user_cache.push(user);
			}
		};

		this.get_user_by_crit = function(crit, search_cache_only) {
			if (search_cache_only) {
				return _.findWhere(this.user_cache, crit);
			}
			var user = _.findWhere(this.room.users, crit);
			return (user || _.findWhere(this.user_cache, crit));
		};

		this.get_user = function(userid, search_cache_only) {
			var crit = { userid: userid };
			return this.get_user_by_crit(crit, search_cache_only);
		};

		this.get_user_by_username = function(username, search_cache_only) {
			var crit = { username: username };
			return this.get_user_by_crit(crit, search_cache_only);
		};

		this.get_room_user_count = function() {
			return this.room.users.length;
		};

		this.add_room_user = function(data) {
			var user = data.user;
			if (!this.get_user(user.username)) {
				this.room.users.push(user);
			}
		};

		this.set_room_list = function(data) {
			this.room_list = data;
		};

		this.set_room = function(room_id, room_name) {
			this.room.id = room_id;
			this.room.name = room_name;
		};

		this.get_room_name = function() {
			return this.room.name;
		};

		this.get_room = function(roomid) {
			return _.findWhere(this.room_list.rooms, {id: roomid});
		};

		this.get_room_id = function() {
			return this.room.id;
		};

		this.get_option = function(name) {
			return this.options[name];
		};

		this.get_usersounds = function() {
			return this.usersounds;
		};

		this.set_usersounds = function(data) {
			this.usersounds = data;
		};

		this.set_current_user = function(user) {
			this.current_user = user;
		};

		this.set_options = function(data) {
			this.options = data;
		};

		this.get_options = function() {
			return this.options;
		};

		this.set_privacy_options = function(status, options) {
			this.privacy_status = status;
			this.privacy_options = options;
		};

		this.set_profilegroup = function(profilegroup) {
			this.profilegroup = profilegroup;
		};

		this.get_privacy_status = function() {
			return this.privacy_status;
		};

		this.get_privacy_options = function() {
			return this.privacy_options;
		};

		this.set_username = function(username) {
			this.username = username;
		};

		this.get_username = function() {
			return this.username;
		};

		this.set_login = function(username, token) {
			this.set_username(username);
			this.token = token;
		};

		this.set_logged_in = function(isloggedin) {
			this.logged_in = isloggedin;
		};

		this.set_challenge = function(challenge) {
			this.challenge = challenge;
		};

		this.logout = function() {
			this.set_logged_in(false);
			this.room = {
				id: 0,
				name: null,
				users: []
			};
		};
	}

	return State;
}));
